
export const PermissionNames = {

    Tenants: 'Tenants',

    Tenants_ViewTab: 'Tenants.ViewTab',

    Tenants_ViewWidgets: 'Tenants.ViewWidgets',

    Tenants_Own: 'Tenants.Own',

    Tenants_CreateOwn: 'Tenants.CreateOwn',

    Tenants_ViewOwn: 'Tenants.ViewOwn',

    Tenants_EditOwn: 'Tenants.EditOwn',

    Tenants_DeleteOwn: 'Tenants.DeleteOwn',

    Tenants_Others: 'Tenants.Others',

    Tenants_CreateOthers: 'Tenants.CreateOthers',

    Tenants_ViewOthers: 'Tenants.ViewOthers',

    Tenants_EditOthers: 'Tenants.EditOthers',

    Tenants_DeleteOthers: 'Tenants.DeleteOthers',

    Tenants_Approve: 'Tenants.Approve',

    Tenants_ApproveOwn: 'Tenants.ApproveOwn',

    Tenants_ApproveOthers: 'Tenants.ApproveOthers',

    Tenants_Restricted: 'Tenants.Restricted',

    Tenants_CreateRestricted: 'Tenants.CreateRestricted',

    Tenants_ViewRestricted: 'Tenants.ViewRestricted',

    Tenants_AssignRestricted: 'Tenants.AssignRestricted',

    Users: 'Users',

    Users_ViewTab: 'Users.ViewTab',

    Users_ViewWidgets: 'Users.ViewWidgets',

    Users_Own: 'Users.Own',

    Users_CreateOwn: 'Users.CreateOwn',

    Users_ViewOwn: 'Users.ViewOwn',

    Users_EditOwn: 'Users.EditOwn',

    Users_DeleteOwn: 'Users.DeleteOwn',

    Users_Others: 'Users.Others',

    Users_CreateOthers: 'Users.CreateOthers',

    Users_ViewOthers: 'Users.ViewOthers',

    Users_EditOthers: 'Users.EditOthers',

    Users_DeleteOthers: 'Users.DeleteOthers',

    Users_Approve: 'Users.Approve',

    Users_ApproveOwn: 'Users.ApproveOwn',

    Users_ApproveOthers: 'Users.ApproveOthers',

    Users_Restricted: 'Users.Restricted',

    Users_CreateRestricted: 'Users.CreateRestricted',

    Users_ViewRestricted: 'Users.ViewRestricted',

    Users_AssignRestricted: 'Users.AssignRestricted',

    InfoHub: 'InfoHub',

    InfoHub_ViewTab: 'InfoHub.ViewTab',

    InfoHub_ViewWidgets: 'InfoHub.ViewWidgets',

    InfoHub_Own: 'InfoHub.Own',

    InfoHub_CreateOwn: 'InfoHub.CreateOwn',

    InfoHub_ViewOwn: 'InfoHub.ViewOwn',

    InfoHub_EditOwn: 'InfoHub.EditOwn',

    InfoHub_DeleteOwn: 'InfoHub.DeleteOwn',

    InfoHub_Others: 'InfoHub.Others',

    InfoHub_CreateOthers: 'InfoHub.CreateOthers',

    InfoHub_ViewOthers: 'InfoHub.ViewOthers',

    InfoHub_EditOthers: 'InfoHub.EditOthers',

    InfoHub_DeleteOthers: 'InfoHub.DeleteOthers',

    InfoHub_Approve: 'InfoHub.Approve',

    InfoHub_ApproveOwn: 'InfoHub.ApproveOwn',

    InfoHub_ApproveOthers: 'InfoHub.ApproveOthers',

    InfoHub_ViewPublic: 'InfoHub.ViewPublic',

    InfoHub_Restricted: 'InfoHub.Restricted',

    InfoHub_CreateRestricted: 'InfoHub.CreateRestricted',

    InfoHub_ViewRestricted: 'InfoHub.ViewRestricted',

    InfoHub_AssignRestricted: 'InfoHub.AssignRestricted',

    Roles: 'Roles',

    Roles_ViewTab: 'Roles.ViewTab',

    Roles_ViewWidgets: 'Roles.ViewWidgets',

    Roles_Own: 'Roles.Own',

    Roles_CreateOwn: 'Roles.CreateOwn',

    Roles_ViewOwn: 'Roles.ViewOwn',

    Roles_EditOwn: 'Roles.EditOwn',

    Roles_DeleteOwn: 'Roles.DeleteOwn',

    Roles_Others: 'Roles.Others',

    Roles_CreateOthers: 'Roles.CreateOthers',

    Roles_ViewOthers: 'Roles.ViewOthers',

    Roles_EditOthers: 'Roles.EditOthers',

    Roles_DeleteOthers: 'Roles.DeleteOthers',

    Roles_Approve: 'Roles.Approve',

    Roles_ApproveOwn: 'Roles.ApproveOwn',

    Roles_ApproveOthers: 'Roles.ApproveOthers',

    Roles_Restricted: 'Roles.Restricted',

    Roles_CreateRestricted: 'Roles.CreateRestricted',

    Roles_ViewRestricted: 'Roles.ViewRestricted',

    Roles_AssignRestricted: 'Roles.AssignRestricted',

    Communication: 'Communication',

    Communication_ViewTab: 'Communication.ViewTab',

    Communication_ViewWidgets: 'Communication.ViewWidgets',

    Communication_Own: 'Communication.Own',

    Communication_CreateOwn: 'Communication.CreateOwn',

    Communication_ViewOwn: 'Communication.ViewOwn',

    Communication_EditOwn: 'Communication.EditOwn',

    Communication_DeleteOwn: 'Communication.DeleteOwn',

    Communication_Others: 'Communication.Others',

    Communication_CreateOthers: 'Communication.CreateOthers',

    Communication_ViewOthers: 'Communication.ViewOthers',

    Communication_EditOthers: 'Communication.EditOthers',

    Communication_DeleteOthers: 'Communication.DeleteOthers',

    Communication_Approve: 'Communication.Approve',

    Communication_ApproveOwn: 'Communication.ApproveOwn',

    Communication_ApproveOthers: 'Communication.ApproveOthers',

    Communication_Restricted: 'Communication.Restricted',

    Communication_CreateRestricted: 'Communication.CreateRestricted',

    Communication_ViewRestricted: 'Communication.ViewRestricted',

    Communication_AssignRestricted: 'Communication.AssignRestricted',

    Integrations: 'Integrations',

    Integrations_ViewTab: 'Integrations.ViewTab',

    Integrations_ViewWidgets: 'Integrations.ViewWidgets',

    Integrations_Own: 'Integrations.Own',

    Integrations_CreateOwn: 'Integrations.CreateOwn',

    Integrations_ViewOwn: 'Integrations.ViewOwn',

    Integrations_EditOwn: 'Integrations.EditOwn',

    Integrations_DeleteOwn: 'Integrations.DeleteOwn',

    Integrations_Others: 'Integrations.Others',

    Integrations_CreateOthers: 'Integrations.CreateOthers',

    Integrations_ViewOthers: 'Integrations.ViewOthers',

    Integrations_EditOthers: 'Integrations.EditOthers',

    Integrations_DeleteOthers: 'Integrations.DeleteOthers',

    Integrations_Approve: 'Integrations.Approve',

    Integrations_ApproveOwn: 'Integrations.ApproveOwn',

    Integrations_ApproveOthers: 'Integrations.ApproveOthers',

    Integrations_Restricted: 'Integrations.Restricted',

    Integrations_CreateRestricted: 'Integrations.CreateRestricted',

    Integrations_ViewRestricted: 'Integrations.ViewRestricted',

    Integrations_AssignRestricted: 'Integrations.AssignRestricted',

    Inventory: 'Inventory',

    Inventory_ViewTab: 'Inventory.ViewTab',

    Inventory_ViewWidgets: 'Inventory.ViewWidgets',

    Inventory_Own: 'Inventory.Own',

    Inventory_CreateOwn: 'Inventory.CreateOwn',

    Inventory_ViewOwn: 'Inventory.ViewOwn',

    Inventory_EditOwn: 'Inventory.EditOwn',

    Inventory_DeleteOwn: 'Inventory.DeleteOwn',

    Inventory_Others: 'Inventory.Others',

    Inventory_CreateOthers: 'Inventory.CreateOthers',

    Inventory_ViewOthers: 'Inventory.ViewOthers',

    Inventory_EditOthers: 'Inventory.EditOthers',

    Inventory_DeleteOthers: 'Inventory.DeleteOthers',

    Inventory_Approve: 'Inventory.Approve',

    Inventory_ApproveOwn: 'Inventory.ApproveOwn',

    Inventory_ApproveOthers: 'Inventory.ApproveOthers',

    Inventory_Restricted: 'Inventory.Restricted',

    Inventory_CreateRestricted: 'Inventory.CreateRestricted',

    Inventory_ViewRestricted: 'Inventory.ViewRestricted',

    Inventory_AssignRestricted: 'Inventory.AssignRestricted',

    Payments: 'Payments',

    Payments_ViewTab: 'Payments.ViewTab',

    Payments_ViewWidgets: 'Payments.ViewWidgets',

    Payments_Own: 'Payments.Own',

    Payments_CreateOwn: 'Payments.CreateOwn',

    Payments_ViewOwn: 'Payments.ViewOwn',

    Payments_EditOwn: 'Payments.EditOwn',

    Payments_DeleteOwn: 'Payments.DeleteOwn',

    Payments_Others: 'Payments.Others',

    Payments_CreateOthers: 'Payments.CreateOthers',

    Payments_ViewOthers: 'Payments.ViewOthers',

    Payments_EditOthers: 'Payments.EditOthers',

    Payments_DeleteOthers: 'Payments.DeleteOthers',

    Payments_Approve: 'Payments.Approve',

    Payments_ApproveOwn: 'Payments.ApproveOwn',

    Payments_ApproveOthers: 'Payments.ApproveOthers',

    Payments_Restricted: 'Payments.Restricted',

    Payments_CreateRestricted: 'Payments.CreateRestricted',

    Payments_ViewRestricted: 'Payments.ViewRestricted',

    Payments_AssignRestricted: 'Payments.AssignRestricted',

    People: 'People',

    People_ViewTab: 'People.ViewTab',

    People_ViewWidgets: 'People.ViewWidgets',

    People_Own: 'People.Own',

    People_CreateOwn: 'People.CreateOwn',

    People_ViewOwn: 'People.ViewOwn',

    People_EditOwn: 'People.EditOwn',

    People_DeleteOwn: 'People.DeleteOwn',

    People_Others: 'People.Others',

    People_CreateOthers: 'People.CreateOthers',

    People_ViewOthers: 'People.ViewOthers',

    People_EditOthers: 'People.EditOthers',

    People_DeleteOthers: 'People.DeleteOthers',

    People_Approve: 'People.Approve',

    People_ApproveOwn: 'People.ApproveOwn',

    People_ApproveOthers: 'People.ApproveOthers',

    People_Restricted: 'People.Restricted',

    People_CreateRestricted: 'People.CreateRestricted',

    People_ViewRestricted: 'People.ViewRestricted',

    People_AssignRestricted: 'People.AssignRestricted',

    Teams: 'Teams',

    Teams_ViewTab: 'Teams.ViewTab',

    Teams_ViewWidgets: 'Teams.ViewWidgets',

    Teams_Own: 'Teams.Own',

    Teams_CreateOwn: 'Teams.CreateOwn',

    Teams_ViewOwn: 'Teams.ViewOwn',

    Teams_EditOwn: 'Teams.EditOwn',

    Teams_DeleteOwn: 'Teams.DeleteOwn',

    Teams_Others: 'Teams.Others',

    Teams_CreateOthers: 'Teams.CreateOthers',

    Teams_ViewOthers: 'Teams.ViewOthers',

    Teams_EditOthers: 'Teams.EditOthers',

    Teams_DeleteOthers: 'Teams.DeleteOthers',

    Teams_Approve: 'Teams.Approve',

    Teams_ApproveOwn: 'Teams.ApproveOwn',

    Teams_ApproveOthers: 'Teams.ApproveOthers',

    Teams_Restricted: 'Teams.Restricted',

    Teams_CreateRestricted: 'Teams.CreateRestricted',

    Teams_ViewRestricted: 'Teams.ViewRestricted',

    Teams_AssignRestricted: 'Teams.AssignRestricted',

    Reports: 'Reports',

    Reports_ViewTab: 'Reports.ViewTab',

    Reports_ViewWidgets: 'Reports.ViewWidgets',

    Reports_Own: 'Reports.Own',

    Reports_CreateOwn: 'Reports.CreateOwn',

    Reports_ViewOwn: 'Reports.ViewOwn',

    Reports_EditOwn: 'Reports.EditOwn',

    Reports_DeleteOwn: 'Reports.DeleteOwn',

    Reports_Others: 'Reports.Others',

    Reports_CreateOthers: 'Reports.CreateOthers',

    Reports_ViewOthers: 'Reports.ViewOthers',

    Reports_EditOthers: 'Reports.EditOthers',

    Reports_DeleteOthers: 'Reports.DeleteOthers',

    Reports_Approve: 'Reports.Approve',

    Reports_ApproveOwn: 'Reports.ApproveOwn',

    Reports_ApproveOthers: 'Reports.ApproveOthers',

    Reports_Restricted: 'Reports.Restricted',

    Reports_CreateRestricted: 'Reports.CreateRestricted',

    Reports_ViewRestricted: 'Reports.ViewRestricted',

    Reports_AssignRestricted: 'Reports.AssignRestricted',

    Scheduling: 'Scheduling',

    Scheduling_ViewTab: 'Scheduling.ViewTab',

    Scheduling_ViewWidgets: 'Scheduling.ViewWidgets',

    Scheduling_Own: 'Scheduling.Own',

    Scheduling_CreateOwn: 'Scheduling.CreateOwn',

    Scheduling_ViewOwn: 'Scheduling.ViewOwn',

    Scheduling_EditOwn: 'Scheduling.EditOwn',

    Scheduling_DeleteOwn: 'Scheduling.DeleteOwn',

    Scheduling_Others: 'Scheduling.Others',

    Scheduling_CreateOthers: 'Scheduling.CreateOthers',

    Scheduling_ViewOthers: 'Scheduling.ViewOthers',

    Scheduling_EditOthers: 'Scheduling.EditOthers',

    Scheduling_DeleteOthers: 'Scheduling.DeleteOthers',

    Scheduling_Approve: 'Scheduling.Approve',

    Scheduling_ApproveOwn: 'Scheduling.ApproveOwn',

    Scheduling_ApproveOthers: 'Scheduling.ApproveOthers',

    Scheduling_Restricted: 'Scheduling.Restricted',

    Scheduling_CreateRestricted: 'Scheduling.CreateRestricted',

    Scheduling_ViewRestricted: 'Scheduling.ViewRestricted',

    Scheduling_AssignRestricted: 'Scheduling.AssignRestricted',

    WorkOrder: 'WorkOrder',

    WorkOrder_ViewTab: 'WorkOrder.ViewTab',

    WorkOrder_ViewWidgets: 'WorkOrder.ViewWidgets',

    WorkOrder_Own: 'WorkOrder.Own',

    WorkOrder_CreateOwn: 'WorkOrder.CreateOwn',

    WorkOrder_ViewOwn: 'WorkOrder.ViewOwn',

    WorkOrder_EditOwn: 'WorkOrder.EditOwn',

    WorkOrder_DeleteOwn: 'WorkOrder.DeleteOwn',

    WorkOrder_Others: 'WorkOrder.Others',

    WorkOrder_CreateOthers: 'WorkOrder.CreateOthers',

    WorkOrder_ViewOthers: 'WorkOrder.ViewOthers',

    WorkOrder_EditOthers: 'WorkOrder.EditOthers',

    WorkOrder_DeleteOthers: 'WorkOrder.DeleteOthers',

    WorkOrder_Approve: 'WorkOrder.Approve',

    WorkOrder_ApproveOwn: 'WorkOrder.ApproveOwn',

    WorkOrder_ApproveOthers: 'WorkOrder.ApproveOthers',

    WorkOrder_Restricted: 'WorkOrder.Restricted',

    WorkOrder_CreateRestricted: 'WorkOrder.CreateRestricted',

    WorkOrder_ViewRestricted: 'WorkOrder.ViewRestricted',

    WorkOrder_AssignRestricted: 'WorkOrder.AssignRestricted',

    Configuration: 'Configuration',

    Configuration_ViewTab: 'Configuration.ViewTab',

    Configuration_ViewWidgets: 'Configuration.ViewWidgets',

    Configuration_Own: 'Configuration.Own',

    Configuration_CreateOwn: 'Configuration.CreateOwn',

    Configuration_ViewOwn: 'Configuration.ViewOwn',

    Configuration_EditOwn: 'Configuration.EditOwn',

    Configuration_DeleteOwn: 'Configuration.DeleteOwn',

    Configuration_Others: 'Configuration.Others',

    Configuration_CreateOthers: 'Configuration.CreateOthers',

    Configuration_ViewOthers: 'Configuration.ViewOthers',

    Configuration_EditOthers: 'Configuration.EditOthers',

    Configuration_DeleteOthers: 'Configuration.DeleteOthers',

    Configuration_Approve: 'Configuration.Approve',

    Configuration_ApproveOwn: 'Configuration.ApproveOwn',

    Configuration_ApproveOthers: 'Configuration.ApproveOthers',

    Configuration_Restricted: 'Configuration.Restricted',

    Configuration_CreateRestricted: 'Configuration.CreateRestricted',

    Configuration_ViewRestricted: 'Configuration.ViewRestricted',

    Configuration_AssignRestricted: 'Configuration.AssignRestricted',
}
