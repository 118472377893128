
import { TeamSummaryReturnDto } from './dto/summaries/teamSummaryReturnDto'
import { TeamReturnDto } from './dto/teamReturnDto'; 
import { TeamCreateDto } from './dto/teamCreateDto';
import { TeamAskDto } from './dto/teamAskDto';
import http from '../httpService';
import { TeamEntityDto } from './dto/entities/teamEntityDto';
import { TeamItemDto } from './dto/entities/teamItemDto';
import { TeamOptionDto } from './dto/entities/teamOptionDto';
import { TeamRecordDto } from './dto/entities/teamRecordDto';
import { TeamStateDto } from './dto/entities/teamStateDto';
import { TeamTypeDto } from './dto/entities/teamTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { TeamTemplateDto } from './dto/entities/teamTemplateDto';
import { TeamEntitySummaryDto } from './dto/summaries/teamEntitySummaryDto';
import { TeamItemSummaryDto } from './dto/summaries/teamItemSummaryDto';
import { TeamOptionSummaryDto } from './dto/summaries/teamOptionSummaryDto';
import { TeamStateSummaryDto } from './dto/summaries/teamStateSummaryDto';
import { TeamTemplateSummaryDto } from './dto/summaries/teamTemplateSummaryDto';
import { TeamTypeSummaryDto } from './dto/summaries/teamTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto'; 
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
import { InfoHubArticleSummaryDto } from "../infoHub/dto/infoHubArticleSummaryDto";
import { CommunicationListSummaryDto } from '../dto/summaries/communicationListSummaryDto';
import { InventoryListSummaryDto } from '../dto/summaries/inventoryListSummaryDto';
import { MenuListSummaryDto } from '../dto/summaries/menuListSummaryDto';
import { PaymentsListSummaryDto } from '../dto/summaries/paymentsListSummaryDto';
import { ReportsListSummaryDto } from '../dto/summaries/reportsListSummaryDto';
import { SchedulingListSummaryDto } from '../dto/summaries/schedulingListSummaryDto';
import { WorkOrderListSummaryDto } from '../dto/summaries/workOrderListSummary';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { IntegrationListSummaryDto } from '../dto/summaries/integrationListSummaryDto';
 

class TeamService { 
 
    public async get(askDto: TeamAskDto): Promise<TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Teams/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Teams/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Teams/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Teams/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: TeamAskDto): Promise<TeamSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, TeamEntitySummaryDto, TeamTypeSummaryDto, TeamTemplateSummaryDto, TeamStateSummaryDto, TeamOptionSummaryDto, TeamItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Teams/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    } 
   
    //------------------------------------------------------------------
  

    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Teams/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Teams/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Teams/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Teams/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: TeamAskDto): Promise<TeamSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, TeamEntitySummaryDto, TeamTypeSummaryDto, TeamTemplateSummaryDto, TeamStateSummaryDto, TeamOptionSummaryDto, TeamItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getRecordsResult = await http.get('api/services/app/Teams/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Teams/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Teams/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }
 
}

export default new TeamService();
