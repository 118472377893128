import { action, observable } from 'mobx';
import { EntityDto } from '../services/dto/entityDto';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import type { RcFile } from 'antd/lib/upload';
import type { EntityCommitContainer, FileUpload } from '../services/dto/fileActionResultDto';
import type { Data, Fields, Meta, S2DataConfig } from '@antv/s2';
import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import type { UploadFile } from 'antd/lib/upload/interface';
import { NoteDto } from '../services/dto/entities/noteDto';
import { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import { NotificationDto } from '../services/dto/entities/notificationDto';
import type { CheckPermissionDto } from '../services/user/dto/checkPermissionDto';
import type { InfoHubReturnDto } from '../services/infoHub/dto/infoHubReturnDto';
import { InfoHubArticleDto } from '../services/infoHub/dto/infoHubArticleDto';
import type { InfoHubCreateDto } from '../services/infoHub/dto/infoHubCreateDto';
import type { InfoHubAskDto } from '../services/infoHub/dto/infoHubAskDto';
import { InfoHubArticleRecordDto } from '../services/infoHub/dto/infoHubArticleRecordDto';
import { DataConsts } from '../components/EntityConfigs/dataConsts';
import { itemTypes } from '../components/EntityConfigs/itemTypes';
import infoHubService from '../services/infoHub/infoHubService';
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
import { InfoHubArticleSectionDto } from '../services/infoHub/dto/infoHubArticleSectionDto';
import type { TemplateItem, TemplateSelectItem } from '../services/infoHub/dto/templateSelectItem';
import { isGranted, L } from '../lib/abpUtility'; 
import { InfoHubSearchRecordDto } from '../services/infoHub/dto/infoHubSearchRecordDto';
  
class InfoHubStore {
  //--------------------------------------------------------------------------

  @observable entity_MaxResultCount: number = 300;
  @observable entity_SkipCount: number = 0;
  @observable action_MaxResultCount: number = 300;
  @observable action_SkipCount: number = 0;

  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;

  //Stored as a group, filtered on retrieval, maybe via State within EntityTabView
  @observable cards_CommentData!: Array<any>;
  @observable cards_BodyData!: Array<any>;
  @observable cards_HeaderData!: Array<any>;
  @observable cards_TagData!: Array<any>;

  @observable cards_BodyConfig!: Array<any>;
  @observable cards_HeaderConfig!: Array<any>;
  @observable cards_TagConfig!: Array<any>;
  @observable articleAsk!: InfoHubAskDto;
  @observable articles!: PagedResultDto<InfoHubArticleDto>;
  @observable articleSummary!: PagedResultDto<any>;
  @observable articleList!: PagedResultDto<any>;

  @observable templatesearch_Mentions: any[] = [];
  @observable templatesearch_Hashtags: any[] = [];
 
  @observable editArticle!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
  @observable articleCreate!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
  @observable articleDelete!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
  @observable articleReturn!: InfoHubReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;

  @observable articleSummaryCondensed!: PagedResultDto<any>;
      
  @observable permissionCheckObject!: CheckPermissionDto;
  @observable profileID: number = 0;
  @observable entityID: number = 0;
  @observable entityType: number = 0;
  @observable currentState: number = 0;
  @observable defaultEntityImages!: Array<UploadFile>;
  @observable defaultEntityFiles!: Array<UploadFile>;
  @observable fileCache!: EntityCommitContainer;

  @observable templatesList!: Array<TemplateSelectItem<TemplateItem>>;

  @observable defaultEntityNotes!: Array<NoteDto>;
  @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;

  @observable currentNotifications: NotificationDto[] = [];

  @observable entityNotesCache!: Array<NoteDto>;
  @observable entityNoteRecordsCache!: Array<NoteRecordDto>; 

  @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

  @observable search_Mentions: any[] = [];
  @observable search_Hashtags: any[] = [];

  @observable dataExportTable!: S2DataConfig;
  @observable dataExportTableData: Data[] = [];
  @observable dataExportTableTotalData: Data[] = [];
  @observable dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title',],
  };

  @observable dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },

  ];

  @observable infoHubStates: any = {
    totalCount: 3,
    items:
      [
        {
          id: 0,
          name: 'draft',
          title: 'Draft',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Submit For Review',
          revertText: '',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: true,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 1,
          name: 'review',
          title: 'Review',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Publish',
          revertText: 'Revise',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 2,
          name: 'Public',
          title: 'Public',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Archive',
          revertText: 'Review',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 3,
          name: 'archived',
          title: 'Archived',
          profileImageID: 0,
          displayType: 0,
          forwardText: '',
          revertText: 'Make Public',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: true,
          noActions: false,
        },
      ]
  }

  @action //gets Notes for initial list
  async getNotes(entityID: number) {

    try {

      var noteAsk = {
        entityType: RelatedEntityTypes.Note,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.InfoHub,
        listFilter: false,
        availableFilter: false,
        entityMaxResultCount: this.entity_MaxResultCount,
        entitySkipCount: this.entity_SkipCount,
        actionMaxResultCount: this.action_MaxResultCount,
        actionSkipCount: this.action_SkipCount
      }
 
      let result = await infoHubService.getNotes(noteAsk); 
  
 
      result.notes.map((x: any) => { 
  
        this.articleReturn.notes.push(x);

      })
 
    }
    catch { }
    return;
  }

  @action //Add Notes to Temp List
  async addNote(note: NoteSummaryDto) { 

    try {

      let noteCache = note;

      var noteItem: NoteDto = {
        id: noteCache.id,
        title: noteCache.title,
        type: noteCache.type,
        relatedEntity: noteCache.relatedEntity,
        replyID: noteCache.replyID,
        customColor: noteCache.customColor,
        created: noteCache.created,
        body: noteCache.body,
        isPrivate: noteCache.isPrivate,
        notifyTagged: noteCache.notifyTagged,
        notifyAll: noteCache.notifyAll
      }

   
      this.entityNotesCache.push(noteItem);
 

      noteCache.noteRecords.map((y: NoteRecordDto) => {
     
        var recordItem: NoteRecordDto = {
          id: y.id,
          noteID: y.noteID,
          relatedUser: y.relatedUser,
          relatedEntity: y.relatedEntity
        }

        this.entityNoteRecordsCache.push(recordItem);
      
      })

 
      if (note.id < 0) {
        let url = await this.getProfileImage(note.currentUserImageID);
        if (url === '_') {
          noteCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          noteCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.entityNotesSummary.items.push(noteCache);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount + 1;
 
    }
    catch { }
    return;
  }

  @action //Remove Notes from Temp List
  async removeNote(recordID: number) {

    try {
      this.entityNotesCache = this.entityNotesCache.filter((x: NoteDto) => x.id !== recordID);
      this.entityNoteRecordsCache = this.entityNoteRecordsCache.filter((x: NoteRecordDto) => x.noteID !== recordID);
      this.entityNotesSummary.items = this.entityNotesSummary.items.filter((x: NoteSummaryDto) => x.id !== recordID);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount - 1;
    }
    catch { }
    return;
  }

  @action //Sync Notes
  async syncNotes(entityID: number) {
   
    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {
 
      this.articleDelete = {
        relatedEntityType: RelatedEntityTypes.Note,
        itemType: itemTypes.InfoHub,
        articles: [],
        articleRecords: [],
        notes: [],
        noteRecords: [],
        articleSections: []
      }

      this.editArticle = {
        relatedEntityType: RelatedEntityTypes.Note,
        itemType: itemTypes.InfoHub,
        articles: [],
        articleRecords: [],
        notes: [],
        noteRecords: [],
        articleSections: []
      }
 
      //Parse default against temp for Deletes 
      this.defaultEntityNotes.map((x: any) => {

        var containsNote = false;
        this.entityNotesCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.articleDelete.notes.push(updateItem);
        }

      })
 
      this.defaultEntityNoteRecords.map((x: any) => {

        var containsNote = false;
        this.entityNoteRecordsCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.articleDelete.noteRecords.push(updateItem);
        }

      })
 
      await infoHubService.deleteNotes(this.articleDelete);
 
      this.entityNotesCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.id = 0;
          updateItem.relatedEntity = entityID;
          this.editArticle.notes.push(updateItem);

        }
      });
 
      this.entityNoteRecordsCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.editArticle.noteRecords.push(updateItem);
        }
      });
 
      
      await infoHubService.addNotes(this.editArticle);
 

    }
    catch { }
    return;
  }
  //--------------------------------------------------------------------------


  @action
  async uploadImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await infoHubService.uploadImage(formData);
    result.uid = result.assetID.toString();
    this.fileCache.fileList.push(result.assetID);

    return result;

  }

  @action
  async uploadProfileImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await infoHubService.uploadImage(formData);

    this.profileID = result.assetID;
    return result;
  }

  @action
  async getProfileImage(recordID: number) {
    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await infoHubService.getImage(recordID);
      return result.url;
    }
  }

  @action
  async getFileDownload(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await infoHubService.getImage(recordID);

      return result;
    }
  }


  @action //gets files for initial list
  async getFiles() {

    try {

      let result = await infoHubService.getFiles(this.entityID, this.entityType);

      //Add to Default Loads
      result.map((x: FileUpload) => {

        if (x.type === 'image/png') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpeg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else {
          this.defaultEntityFiles.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }

        return this.fileCache.fileList.push(x.assetID);
      })
    }
    catch { }
    return;
  }

  @action // 
  async removeFileFromList(file: UploadFile) {
    this.fileCache.fileList = this.fileCache.fileList.filter((x: number) => x.toString() !== file.uid);
    return;
  }

  @action
  async initFileStores() {
    this.defaultEntityImages = [];
    this.defaultEntityFiles = [];
    this.fileCache =
    {
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      fileList: []
    };
  }

  @action
  async getTemplatesList() {
    this.templatesList = [];
    var templateUpdateObject: any = [];
    //--------------------------------------------------------


    if (isGranted(itemTypes.Communication_text) === true) {
      var updateObject: any = {
        value: itemTypes.Communication,
        title: itemTypes.Communication_text,
        children: []
      };

     
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Integrations_text) === true) {
      var updateObject: any = {
        value: itemTypes.Integrations,
        title: itemTypes.Integrations_text,
        children: []
      };
 

      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Inventory_text) === true) {
      var updateObject: any = {
        value: itemTypes.Inventory,
        title: itemTypes.Inventory_text,
        children: []
      };

     
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Menu_text) === true) {
      var updateObject: any = {
        value: itemTypes.Menu,
        title: itemTypes.Menu_text,
        children: []
      };
 
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Payments_text) === true) {
      var updateObject: any = {
        value: itemTypes.Payments,
        title: itemTypes.Payments_text,
        children: []
      };
 
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Persons_text) === true) {
      var updateObject: any = {
        value: itemTypes.Persons,
        title: itemTypes.Persons_text,
        children: []
      };

    
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Reports_text) === true) {
      var updateObject: any = {
        value: itemTypes.Reports,
        title: itemTypes.Reports_text,
        children: []
      };

    
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Schedules_text) === true) {
      var updateObject: any = {
        value: itemTypes.Schedules,
        title: itemTypes.Schedules_text,
        children: []
      };

      
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.Teams_text) === true) {
      var updateObject: any = {
        value: itemTypes.Teams,
        title: itemTypes.Teams_text,
        children: []
      };

      
      templateUpdateObject.push(updateObject);
    };

    if (isGranted(itemTypes.WorkOrders_text) === true) {
      var updateObject: any = {
        value: itemTypes.WorkOrders,
        title: itemTypes.WorkOrders_text,
        children: []
      };

      
      templateUpdateObject.push(updateObject);
    };

    //--------------------------------------------------------

    if (isGranted(itemTypes.Configuration_text) === true) {


      templateUpdateObject.push({
        value: itemTypes.InfoHub,
        title: L('General Support'),
      });

      templateUpdateObject.push({
        value: itemTypes.Configuration,
        title: itemTypes.Configuration_text,
        children: [
          {
            value: itemTypes.Configuration + '_' + itemTypes.Templates,
            title: itemTypes.Templates_text,
          },
          {
            value: itemTypes.Configuration + '_' + itemTypes.Types,
            title: itemTypes.Types_text,
          },
          {
            value: itemTypes.Configuration + '_' + itemTypes.Items_text,
            title: itemTypes.Items_text,
          },
          {
            value: itemTypes.Configuration + '_' + itemTypes.Options,
            title: itemTypes.Options_text,
          },
          {
            value: itemTypes.Configuration + '_' + itemTypes.States,
            title: itemTypes.States_text,
          },
        ]
      });

    }

    let containsAdmin = false;
    var adminObject: any = {
      value: itemTypes.Administration,
      title: itemTypes.Administration_text,
      children: []
    };

    if (isGranted(itemTypes.Tenants_text) === true) {
      containsAdmin = true;
      let tenantObject = {
        value: itemTypes.Administration + '_' + itemTypes.Tenants,
        title: itemTypes.Tenants_text,
      };

      adminObject.children.push(tenantObject);
    }

    if (isGranted(itemTypes.Roles_text) === true) {
      containsAdmin = true;
      let rolesObject = {
        value: itemTypes.Administration + '_' + itemTypes.Roles,
        title: itemTypes.Roles_text,
      };

      adminObject.children.push(rolesObject);
    }

    if (isGranted(itemTypes.Users_text) === true) {
      containsAdmin = true;
      let usersObject = {
        value: itemTypes.Administration + '_' + itemTypes.Users,
        title: itemTypes.Users_text,
      };

      adminObject.children.push(usersObject);
    }

    if (containsAdmin === true) {
      templateUpdateObject.push(adminObject);
    }

    //--------------------------------------------------------

    this.templatesList = templateUpdateObject;
  }

  @action
  async initUpdateObject(entityType: number, itemType: number) {


    this.entityNotesCache = [];
    this.entityNoteRecordsCache = [];
    this.defaultEntityNotes = []; 

    this.defaultEntityNoteRecords = [];
    this.entityNotesSummary = {
      totalCount: 0,
      items: []
    }

    this.articles = {
      totalCount: 0,
      items: []
    }

    this.articleList = {
      totalCount: 0,
      items: []
    }

    this.editArticle = {
      relatedEntityType: 0,
      itemType: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: [],
      articleSections: []
    }

    this.articleCreate = {
      relatedEntityType: entityType,
      itemType: itemType,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: [],
      articleSections: []
    }

    this.articleDelete = {
      relatedEntityType: 0,
      itemType: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: [],
      articleSections: []
    }

    this.articleReturn = {
      value: '',
      description: '',
      id: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: [],
      articleSections: []
    }



  }

  //--------------------------------------------------------------------------

  @action
  async getEntity(askDto: EntityDto) {
    this.entityID = askDto.id;
    this.entityType = RelatedEntityTypes.Entity;
    //this.getFiles();

    this.articleReturn = await infoHubService.get({
      entityType: RelatedEntityTypes.Entity,
      entityID: askDto.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }); 

 

  }

  @action
  async createEntity() {
 
 

    let result = await infoHubService.create(this.articleCreate);
    this.articleReturn = result;
 
    this.articleSummary =
    {
      totalCount: 0,
      items: []
    }
    this.articleSummaryCondensed =
    {
      totalCount: 0,
      items: []
    }

 

    this.fileCache.entityType = this.articleCreate.relatedEntityType;
    this.fileCache.entityID = this.articleCreate.articles[0].id;

    //await infoHubService.commitEntityFiles(this.fileCache);
    await this.syncNotes(this.fileCache.entityID);
    

  }

  @action
  async updateEntity() {
 
 
 
    let result = await infoHubService.update(this.articleCreate);
    this.articleReturn = result;
  
 
    this.fileCache.entityType = this.articleCreate.relatedEntityType;
    this.fileCache.entityID = this.articleCreate.articles[0].id;

    this.articleCreate.articleRecords = [];
    await infoHubService.commitEntityFiles(this.fileCache);
    await this.syncNotes(this.articleCreate.articles[0].id);

  }


  @action
  async deleteEntity(input: EntityDto) {


    this.articleSummary.items = this.articleSummary.items.filter((x: any) => x.id !== input.id);
    this.articleSummaryCondensed.items = this.articleSummaryCondensed.items.filter((x: any) => x.id !== input.id);

    this.articleDelete.articles.push({
      id: input.id,
      tags: '',
      category: 0,
      relatedTemplate: 0,
      profileImageID: 0,
      name: '',
      title: '',
      body: '',
      currentState: 0,
      isActive: false,
      owner: 0,
      creatorID: 0,
      lastModifiedByID: 0,
      currentlyApproved: false
    });

    this.articleDelete.relatedEntityType = RelatedEntityTypes.Entity;
    this.articleDelete.itemType = itemTypes.InfoHub;

    await infoHubService.delete(this.articleDelete);
  };

  @action
  async getArticleSummary(keyword: any) {

    this.getTemplatesList();

    this.articleSummary =
    {
      totalCount: 0,
      items: []
    }

    this.articleSummaryCondensed =
    {
      totalCount: 0,
      items: []
    }

    this.dataExportTableData = [];
    this.dataExportTableTotalData = [];
    this.search_Hashtags = [];

    let articleSummary = await infoHubService.getArticleSummaryView({
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });
 

    if (articleSummary !== undefined) {
      this.articleSummary =
      {
        totalCount: articleSummary.articles.length,
        items: articleSummary.articles
      }

      this.articleSummaryCondensed =
      {
        totalCount: articleSummary.articles.length,
        items: articleSummary.articles
      } 
    }

    // Loop Retrieved States
    articleSummary.articles.map((x: any) => {

      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "currentState": x.currentState === undefined ? 'Unknown' : x.currentState,
        "isActive": x.isActive === undefined ? 'Unknown' : x.isActive,
       
      }

      return this.dataExportTableData.push(dataItem);

    });

    this.dataExportTable = {
      fields: this.dataTableFields,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    }

    articleSummary.searchRecords.map((x: InfoHubSearchRecordDto) => {
      //this.search_Hashtags.push(x.searchString); 
    });

  }

  @action
  async getAllArticles() {

    this.articles = {
      totalCount: 0,
      items: []
    }

    let result = await infoHubService.get({
      entityType: RelatedEntityTypes.Option,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    this.articleList =
    {
      totalCount: result.articles.length,
      items: result.articles
    }

  }

  @action
  async getCardData(filter: any) {




    this.cards_CommentData = [{
      email: 'Product was Sold',
      gender: 'string',
      name: {
        first: 'A few moments ago',
        last: 'Noah James',
        title: 'string',
      },
      nat: 'string',
      picture: {
        large: 'https://swilab.com/images/profileExamples/profileExample1.png',
        medium: 'string',
        thumbnail: 'string',
      },
    },
    {
      email: 'Product was Purchased',
      gender: 'string',
      name: {
        first: 'A few moments ago',
        last: 'Abigail Smith',
        title: 'string',
      },
      nat: 'string',
      picture: {
        large: 'https://swilab.com/images/profileExamples/profileExample3.png',
        medium: 'string',
        thumbnail: 'string',
      },

    },];

    this.cards_HeaderData = [{
      year: '',
      value: 30,
      type: 'Not for Sale',
    },
    {
      year: '',
      value: 50,
      type: 'Processing',
    },
    {
      year: '',
      value: 70,
      type: 'Available',
    },];

    this.cards_HeaderConfig = [{
      data: this.cards_HeaderData.reverse(),
      isStack: true,
      xField: 'value',
      yField: 'year',
      seriesField: 'type',
      label: {
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' },
        ],
      },
    }];

    this.cards_BodyData = [
      {
        type: 'Main Warehouse',
        value: 67,
      },
      {
        type: 'Location A',
        value: 45,
      },
      {
        type: 'Location B',
        value: 21,
      },
      {
        type: 'In Transit',
        value: 17,
      },];
    this.cards_BodyConfig = [{
      appendPadding: 0,
      data: this.cards_BodyData,
      innerRadius: 0.71,
      angleField: 'value',
      colorField: 'type',
      radius: 1,

      label: {
        type: 'inner',
        autoRotate: true,
        style: { fontSize: 14, textAlign: 'center' },

      },

    }];

    this.cards_TagData = [
      {
        title: 'AT&T',
        color: 'blue'
      },
      {
        title: 'Verizon',
        color: 'red'
      },
      {
        title: 'Boost',
        color: 'orange'
      },
    ];

    this.cards_TagConfig = [];



  }
 
  @action
  async clearCardData() {

    this.cards_CommentData = [];
    this.cards_BodyData = [];
    this.cards_HeaderData = [];
    this.cards_TagData = [];
    this.cards_BodyConfig = [];
    this.cards_HeaderConfig = [];
    this.cards_TagConfig = [];
  }

  //--------------------------------------------------------------------------


}

export default InfoHubStore;

