export const TemplateFormTypes = {
    isApprovalStep: -1,
    approvalType: -2,
    approvalFormat: -3,
    applyToAll: -4,
    requiredRoles: -5,
    securityScope: -6,
    actions: -7,
    approvalGroup: -8,
    securityGroup: -9, 

}
