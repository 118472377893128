import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import * as React from 'react'; 

const Loading = () => (
  <div style={{ paddingTop: '20%', textAlign: 'center' }}>
   <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#dadada' }} spin />} />
  </div>
);

export default Loading;
