import { action, observable } from 'mobx';
import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput';
import  SMSVerification  from '../services/account/dto/smsVerification';
import accountService from '../services/account/accountService';
import type { RegisterInput } from '../services/account/dto/registerInput';

import type { SignUpInput } from '../services/account/dto/signUpInput';
import type { ContactUsInput } from '../services/account/dto/contactUsInput';
import type { ScheduleDemoInput } from '../services/account/dto/scheduleDemoInput';

import  RegisterOutput  from '../services/account/dto/registerOutput';
import  ForgotPasswordDto  from '../services/user/dto/forgotPasswordDto';

class AccountStore {
  @observable tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();
  @observable registerOutput: RegisterOutput = new RegisterOutput();
  @observable verifySMSOutput: SMSVerification = new SMSVerification();
  @observable forgotPasswordOutput: ForgotPasswordDto = new ForgotPasswordDto();
  @observable registerInput!: RegisterInput;
  @observable contactInput!: ContactUsInput;
  @observable signUpInput!: SignUpInput;
  @observable scheduleDemoInput!: ScheduleDemoInput;
  @observable forgotPasswordInput!: ForgotPasswordDto;

  @action
  public isTenantAvailable = async (tenancyName: string) => {
    this.tenant = await accountService.isTenantAvailable({ tenancyName: tenancyName });

    //console.warn('---------this.tenant----------')
    //console.warn(JSON.stringify(this.tenant))


    //this.tenant = {
   //   state: 1,
   //  tenantId:  
   // }
  };

  @action
  public storeRegistrationInfo = async (registerInput: RegisterInput) => {

    this.registerInput = {
      
      name: registerInput.name,
      company: "None",
      surname: registerInput.surname,
      userName: registerInput.emailAddress,
      email: registerInput.emailAddress,
      emailAddress: registerInput.emailAddress,
      password: registerInput.password,
      captchaResponse: 'string',
      phoneOne: registerInput.phoneOne,
    }

    await this.verifySMS();
    
  };

  @action
  public storeContactInfo = async (contactUsInput: ContactUsInput) => {
 
    this.contactInput = { 
      email: contactUsInput.email,
      name: contactUsInput.name,
      message: contactUsInput.message,
      scheduleDate: contactUsInput.scheduleDate,
      scheduleID: contactUsInput.scheduleID,
      interest: contactUsInput.interest
    }
  };

  @action
  public storeSignUp = async (signUpInput: SignUpInput) => {

    this.signUpInput = {
      
      name: signUpInput.email,
      phone: signUpInput.phone,
      companyName: signUpInput.companyName,
      email: signUpInput.email,
      criteria: ['none']
    }
  };

  @action
  public storeScheduleDemo = async (scheduleDemoInput: ScheduleDemoInput) => {

    this.scheduleDemoInput = {
      
      name: scheduleDemoInput.name,
      surname: scheduleDemoInput.surname,
      email: scheduleDemoInput.email,
      message: scheduleDemoInput.message
    }
  };

  @action
  public storePasswordResetInfo = async (email: string, resetType: number) => {

    this.forgotPasswordInput = {
      email: email,
      type: resetType,
      returnCode: 0,
      userID: 0,
      newPassword: 'none'
    }

    
    let result = await accountService.forgotPassword(this.forgotPasswordInput);
    this.forgotPasswordOutput = result;
    
  };

  @action
  public register = async () => {
    var returnObject = await accountService.register(this.registerInput);
    this.registerOutput = returnObject;
  };

  @action
  public contact = async (contact: ContactUsInput) => {
    var returnObject = await accountService.contactUs(contact);
    this.registerOutput = returnObject;
  };

  @action
  public contactInvoice = async (location: any) => {
    var returnObject = await accountService.contactInvoice(this.contactInput);
    this.registerOutput = returnObject;


     const { state } =  location;
      window.location = state ? state.from.pathname : '/logout';
  };

  @action
  public signUp = async () => {
    var returnObject = await accountService.signUp(this.signUpInput);
    this.registerOutput = returnObject;
  };

  @action
  public scheduleDemo = async (contact: ContactUsInput) => {
    var returnObject = await accountService.scheduleDemo(contact);
    this.registerOutput = returnObject;
  };

  @action
  public verifySMS = async  () => {
    var returnObject = await accountService.verifySMS({number: this.registerInput.phoneOne});
    this.verifySMSOutput = returnObject;
  };
  
  @action
  async forgotPasswordReset(newPassword: string) {
    
   this.forgotPasswordOutput.newPassword = newPassword;
    let result = await accountService.forgotPasswordReset(this.forgotPasswordOutput);
    return result;
  }

}

export default AccountStore;
