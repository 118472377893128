import { action, observable } from 'mobx';
import type { AreaChart_Configuration, AreaChart_DataModel, AxisModel } from '../components/DataModels/areaChart_Configuration'
import type { BarChart_Configuration, BarChart_DataModel } from '../components/DataModels/barChart_Configuration';
import type { BoxPlot_Configuration, BoxPlot_DataModel, BoxStyleModel } from '../components/DataModels/boxPlot_Configuration';
import type { Bullet_Configuration, Bullet_DataModel } from '../components/DataModels/bullet_Configuration';
import type { CirclePacking_Configuration, CirclePacking_DataModel, CirclePacking_DataModelChildren } from '../components/DataModels/circlePacking_Configuration';
import type { ColumnGraph_Configuration, ColumnGraph_DataModel } from '../components/DataModels/columnGraph_Configuration';
import type { DonutChart_Configuration, DonutChart_DataModel } from '../components/DataModels/donutChart_Configuration';
import type { FunnelChart_Configuration, FunnelChart_DataModel } from '../components/DataModels/funnelChart_Configuration';
import type { Gauge_Configuration } from '../components/DataModels/gauge_Configuration';
import type { HeatMap_Configuration, HeatMap_DataModel } from '../components/DataModels/heatMap_Configuration';
import type { Histogram_Configuration, Histogram_DataModel } from '../components/DataModels/histogram_Configuration';
import type { LineChart_Configuration, LineChart_DataModel } from '../components/DataModels/lineChart_Configuration';
import type { Liquid_Configuration } from '../components/DataModels/liquid_Configuration';
import type { MultiLineChart_Configuration, MultiLineChart_DataModel } from '../components/DataModels/multiLineChart_Configuration'; 
import type { PieChart_Configuration, PieChart_DataModel } from '../components/DataModels/pieChart_Configuration';
import type { Progress_Configuration } from '../components/DataModels/progress_Configuration';
import type { RadarChart_Configuration } from '../components/DataModels/radarChart_Configuration';
import type { RadialBarChart_Configuration, RadialBarChart_DataModel } from '../components/DataModels/radialBarChart_Configuration';
import type { Ring_Configuration } from '../components/DataModels/ring_Configuration';
import type { RoseChart_Configuration, RoseChart_DataModel } from '../components/DataModels/roseChart_Configuration';
import type { SankeyPlot_Configuration, SankeyPlot_DataModel } from '../components/DataModels/sankeyPlot_Configuration';
import type { ScatterPlot_Configuration, ScatterPlot_DataModel } from '../components/DataModels/scatterPlot_Configuration';
import type { StockPlot_Configuration, StockPlot_DataModel } from '../components/DataModels/stockPlot_Configuration';
import type { SunburstPlot_Configuration, SunburstPlot_DataModel } from '../components/DataModels/sunburstPlot_Configuration';
import type { VennPlot_Configuration, VennPlot_DataModel } from '../components/DataModels/vennPlot_Configuration';
import type { ViolinPlot_Configuration, ViolinPlot_DataModel } from '../components/DataModels/violinPlot_Configuration';
import type { WaterfallChart_Configuration, WaterfallChart_DataModel } from '../components/DataModels/waterfallChart_Configuration';
import { DashboardItemTypes } from '../components/EntityConfigs/dashboardItemTypes';
import dashboardService from '../services/dashboard/dashboardService';
import type { DashbordAskDto } from '../services/dashboard/dto/dashboardAskDto';
import type { DashboardLayoutDto, DashboardRowDto, DashboardColumnDto } from '../services/dashboard/dto/dashboardLayoutDto';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import { UploadFile } from 'antd';
import { FormGridDto } from '../components/EntityConfigs/formGridDto';
import type { CommunicationAskDto } from '../services/communication/dto/communicationAskDto';
import type { CommunicationCreateDto } from '../services/communication/dto/communicationCreateDto';
import type { CommunicationReturnDto } from '../services/communication/dto/communicationReturnDto';
import type { CommunicationEntityDto } from '../services/communication/dto/entities/communicationEntityDto';
import type { CommunicationItemDto } from '../services/communication/dto/entities/communicationItemDto';
import type { CommunicationOptionDto } from '../services/communication/dto/entities/communicationOptionDto';
import type { CommunicationRecordDto } from '../services/communication/dto/entities/communicationRecordDto';
import type { CommunicationStateDto } from '../services/communication/dto/entities/communicationStateDto';
import type { CommunicationTemplateDto } from '../services/communication/dto/entities/communicationTemplateDto';
import type { CommunicationTypeDto } from '../services/communication/dto/entities/communicationTypeDto';
import type { NoteDto } from '../services/dto/entities/noteDto';
import type { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import type { NotificationDto } from '../services/dto/entities/notificationDto';
import type { EntityCommitContainer } from '../services/dto/fileActionResultDto';
import type { TemplateRecordDto } from '../services/dto/records/templateRecordDto';
import type { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
import type { CommunicationListSummaryDto } from '../services/dto/summaries/communicationListSummaryDto';
import type { InventoryListSummaryDto } from '../services/dto/summaries/inventoryListSummaryDto';
import type { MenuListSummaryDto } from '../services/dto/summaries/menuListSummaryDto';
import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import type { PaymentsListSummaryDto } from '../services/dto/summaries/paymentsListSummaryDto';
import type { PersonListSummaryDto } from '../services/dto/summaries/personListSummaryDto';
import type { ReportsListSummaryDto } from '../services/dto/summaries/reportsListSummaryDto';
import type { SchedulingListSummaryDto } from '../services/dto/summaries/schedulingListSummaryDto';
import type { TeamListSummaryDto } from '../services/dto/summaries/teamListSummaryDto';
import type { UserListSummaryDto } from '../services/dto/summaries/userListSummaryDto';
import type { WorkOrderListSummaryDto } from '../services/dto/summaries/workOrderListSummary';
import type { ValueReturnDto } from '../services/dto/values/valueReturnDto';
import type { InfoHubArticleDto } from '../services/infoHub/dto/infoHubArticleDto';
import type { InfoHubArticleRecordDto } from '../services/infoHub/dto/infoHubArticleRecordDto';
import type { InfoHubArticleSectionDto } from '../services/infoHub/dto/infoHubArticleSectionDto';
import type { InfoHubAskDto } from '../services/infoHub/dto/infoHubAskDto';
import type { InfoHubCreateDto } from '../services/infoHub/dto/infoHubCreateDto';
import type { InfoHubReturnDto } from '../services/infoHub/dto/infoHubReturnDto';
import type { IntegrationsEntityDto } from '../services/integrations/dto/entities/integrationsEntityDto';
import type { IntegrationsItemDto } from '../services/integrations/dto/entities/integrationsItemDto';
import type { IntegrationsOptionDto } from '../services/integrations/dto/entities/integrationsOptionDto';
import type { IntegrationsRecordDto } from '../services/integrations/dto/entities/integrationsRecordDto';
import type { IntegrationsStateDto } from '../services/integrations/dto/entities/integrationsStateDto';
import type { IntegrationsTemplateDto } from '../services/integrations/dto/entities/integrationsTemplateDto';
import type { IntegrationsTypeDto } from '../services/integrations/dto/entities/integrationsTypeDto';
import type { IntegrationsAskDto } from '../services/integrations/dto/integrationsAskDto';
import type { IntegrationsCreateDto } from '../services/integrations/dto/integrationsCreateDto';
import type { IntegrationsReturnDto } from '../services/integrations/dto/integrationsReturnDto';
import type { InventoryEntityDto } from '../services/inventory/dto/entities/inventoryEntityDto';
import type { InventoryItemDto } from '../services/inventory/dto/entities/inventoryItemDto';
import type { InventoryOptionDto } from '../services/inventory/dto/entities/inventoryOptionDto';
import type { InventoryRecordDto } from '../services/inventory/dto/entities/inventoryRecordDto';
import type { InventoryStateDto } from '../services/inventory/dto/entities/inventoryStateDto';
import type { InventoryTemplateDto } from '../services/inventory/dto/entities/inventoryTemplateDto';
import type { InventoryTypeDto } from '../services/inventory/dto/entities/inventoryTypeDto';
import type { InventoryAskDto } from '../services/inventory/dto/inventoryAskDto';
import type { InventoryCreateDto } from '../services/inventory/dto/inventoryCreateDto';
import type { InventoryReturnDto } from '../services/inventory/dto/inventoryReturnDto';
import type { MenuEntityDto } from '../services/menu/dto/entities/menuEntityDto';
import type { MenuItemDto } from '../services/menu/dto/entities/menuItemDto';
import type { MenuOptionDto } from '../services/menu/dto/entities/menuOptionDto';
import type { MenuRecordDto } from '../services/menu/dto/entities/menuRecordDto';
import type { MenuStateDto } from '../services/menu/dto/entities/menuStateDto';
import type { MenuTemplateDto } from '../services/menu/dto/entities/menuTemplateDto';
import type { MenuTypeDto } from '../services/menu/dto/entities/menuTypeDto';
import type { MenuAskDto } from '../services/menu/dto/menuAskDto';
import type { MenuCreateDto } from '../services/menu/dto/menuCreateDto';
import type { MenuReturnDto } from '../services/menu/dto/menuReturnDto';
import type { PaymentEntityDto } from '../services/payments/dto/entities/paymentEntityDto';
import type { PaymentItemDto } from '../services/payments/dto/entities/paymentItemDto';
import type { PaymentOptionDto } from '../services/payments/dto/entities/paymentOptionDto';
import type { PaymentRecordDto } from '../services/payments/dto/entities/paymentRecordDto';
import type { PaymentStateDto } from '../services/payments/dto/entities/paymentStateDto';
import type { PaymentTemplateDto } from '../services/payments/dto/entities/paymentTemplateDto';
import type { PaymentTypeDto } from '../services/payments/dto/entities/paymentTypeDto';
import type { PaymentAskDto } from '../services/payments/dto/paymentAskDto';
import type { PaymentCreateDto } from '../services/payments/dto/paymentCreateDto';
import type { PaymentReturnDto } from '../services/payments/dto/paymentReturnDto';
import type { PeopleEntityDto } from '../services/people/dto/entities/peopleEntityDto';
import type { PeopleItemDto } from '../services/people/dto/entities/peopleItemDto';
import type { PeopleOptionDto } from '../services/people/dto/entities/peopleOptionDto';
import type { PeopleRecordDto } from '../services/people/dto/entities/peopleRecordDto';
import type { PeopleStateDto } from '../services/people/dto/entities/peopleStateDto';
import type { PeopleTemplateDto } from '../services/people/dto/entities/peopleTemplateDto';
import type { PeopleTypeDto } from '../services/people/dto/entities/peopleTypeDto';
import type { PeopleAskDto } from '../services/people/dto/peopleAskDto';
import type { PeopleCreateDto } from '../services/people/dto/peopleCreateDto';
import type { PeopleReturnDto } from '../services/people/dto/peopleReturnDto';
import type { ReportEntityDto } from '../services/report/dto/entities/reportEntityDto';
import type { ReportItemDto } from '../services/report/dto/entities/reportItemDto';
import type { ReportOptionDto } from '../services/report/dto/entities/reportOptionDto';
import type { ReportRecordDto } from '../services/report/dto/entities/reportRecordDto';
import type { ReportStateDto } from '../services/report/dto/entities/reportStateDto';
import type { ReportTemplateDto } from '../services/report/dto/entities/reportTemplateDto';
import type { ReportTypeDto } from '../services/report/dto/entities/reportTypeDto';
import type { ReportAskDto } from '../services/report/dto/reportAskDto';
import type { ReportCreateDto } from '../services/report/dto/reportCreateDto';
import type { ReportReturnDto } from '../services/report/dto/reportReturnDto';
import type { GetAllRoleOutput } from '../services/role/dto/getAllRoleOutput';
import type { SchedulingEntityDto } from '../services/scheduling/dto/entities/schedulingEntityDto';
import type { SchedulingItemDto } from '../services/scheduling/dto/entities/schedulingItemDto';
import type { SchedulingOptionDto } from '../services/scheduling/dto/entities/schedulingOptionDto';
import type { SchedulingRecordDto } from '../services/scheduling/dto/entities/schedulingRecordDto';
import type { SchedulingStateDto } from '../services/scheduling/dto/entities/schedulingStateDto';
import type { SchedulingTemplateDto } from '../services/scheduling/dto/entities/schedulingTemplateDto';
import type { SchedulingTypeDto } from '../services/scheduling/dto/entities/schedulingTypeDto';
import type { SchedulingAskDto } from '../services/scheduling/dto/schedulingAskDto';
import type { SchedulingCreateDto } from '../services/scheduling/dto/schedulingCreateDto';
import type { SchedulingReturnDto } from '../services/scheduling/dto/schedulingReturnDto';
import type { TeamEntityDto } from '../services/team/dto/entities/teamEntityDto';
import type { TeamItemDto } from '../services/team/dto/entities/teamItemDto';
import type { TeamOptionDto } from '../services/team/dto/entities/teamOptionDto';
import type { TeamRecordDto } from '../services/team/dto/entities/teamRecordDto';
import type { TeamStateDto } from '../services/team/dto/entities/teamStateDto';
import type { TeamTemplateDto } from '../services/team/dto/entities/teamTemplateDto';
import type { TeamTypeDto } from '../services/team/dto/entities/teamTypeDto';
import type { TeamAskDto } from '../services/team/dto/teamAskDto';
import type { TeamCreateDto } from '../services/team/dto/teamCreateDto';
import type { TeamReturnDto } from '../services/team/dto/teamReturnDto';
import type { WorkOrderEntityDto } from '../services/workOrder/dto/entities/workOrderEntityDto';
import type { WorkOrderItemDto } from '../services/workOrder/dto/entities/workOrderItemDto';
import type { WorkOrderOptionDto } from '../services/workOrder/dto/entities/workOrderOptionDto';
import type { WorkOrderRecordDto } from '../services/workOrder/dto/entities/workOrderRecordDto';
import type { WorkOrderStateDto } from '../services/workOrder/dto/entities/workOrderStateDto';
import type { WorkOrderTemplateDto } from '../services/workOrder/dto/entities/workOrderTemplateDto';
import type { WorkOrderTypeDto } from '../services/workOrder/dto/entities/workOrderTypeDto';
import type { WorkOrderAskDto } from '../services/workOrder/dto/workOrderAskDto';
import type { WorkOrderCreateDto } from '../services/workOrder/dto/workOrderCreateDto';
import type { WorkOrderReturnDto } from '../services/workOrder/dto/workOrderReturnDto';
import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import communicationService from '../services/communication/communicationService';
import inventoryService from '../services/inventory/inventoryService';
import menuService from '../services/menu/menuService';
import peopleService from '../services/people/peopleService';
import schedulingService from '../services/scheduling/schedulingService';
import paymentService from '../services/payments/paymentService';
import reportService from '../services/report/reportService';
import integrationService from '../services/integrations/integrationService';
import teamService from '../services/team/teamService';
import workOrderService from '../services/workOrder/workOrderService';
import { EntityDto } from '../services/dto/entityDto'; 
import { StateTypes } from '../components/EntityConfigs/stateTypes'; 
import dayjs from 'dayjs';
 



class DashboardStore {

    @observable user_Dashboard!: DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>;
    @observable public_Dashboard!: DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>;

    @observable entity_MaxResultCount: number = 300;
    @observable entity_SkipCount: number = 0;
    @observable action_MaxResultCount: number = 300;
    @observable action_SkipCount: number = 0;

    @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;

    @observable entityAsk!: DashbordAskDto;
    //--------------------------------------------------------------------------

    @observable entitySummary!: PagedResultDto<any>;
    @observable entities!: PagedResultDto<any>;
    @observable entitiesList!: PagedResultDto<any>;
    @observable entitySummaryCondensed!: PagedResultDto<any>;

    @observable communication_entityAsk!: CommunicationAskDto;
    @observable communication_entityCreate!: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable communication_entityDelete!: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable communication_entityEdit!: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable communication_entityReturn!: CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable communication_entitySummary!: PagedResultDto<any>;
    @observable communication_entities!: PagedResultDto<any>;
    @observable communication_entitiesList!: PagedResultDto<any>;
    @observable communication_entitySummaryCondensed!: PagedResultDto<any>;

    @observable infoHub_articleAsk!: InfoHubAskDto;
    @observable infoHub_editArticle!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
    @observable infoHub_articleCreate!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
    @observable infoHub_articleDelete!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
    @observable infoHub_articleReturn!: InfoHubReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;

    @observable articles!: PagedResultDto<InfoHubArticleDto>;
    @observable articleSummary!: PagedResultDto<any>;
    @observable articleList!: PagedResultDto<any>;
    @observable articleSummaryCondensed!: PagedResultDto<any>;

    @observable integrations_entityAsk!: IntegrationsAskDto;
    @observable integrations_entityCreate!: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable integrations_entityDelete!: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable integrations_entityReturn!: IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;
    @observable integrations_entityEdit!: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;

    @observable integrations_entitySummary!: PagedResultDto<any>;
    @observable integrations_entities!: PagedResultDto<any>;
    @observable integrations_entitiesList!: PagedResultDto<any>;
    @observable integrations_entitySummaryCondensed!: PagedResultDto<any>;

    @observable inventory_entityAsk!: InventoryAskDto;
    @observable inventory_entityCreate!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable inventory_entityEdit!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable inventory_entityDelete!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable inventory_entityReturn!: InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable inventory_entitySummary!: PagedResultDto<any>;
    @observable inventory_entities!: PagedResultDto<any>;
    @observable inventory_entitiesList!: PagedResultDto<any>;
    @observable inventory_entitySummaryCondensed!: PagedResultDto<any>;

    @observable menu_entityAsk!: MenuAskDto;
    @observable menu_entityCreate!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable menu_entityEdit!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable menu_entityDelete!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable menu_entityReturn!: MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable menu_entitySummary!: PagedResultDto<any>;
    @observable menu_entities!: PagedResultDto<any>;
    @observable menu_entitiesList!: PagedResultDto<any>;
    @observable menu_entitySummaryCondensed!: PagedResultDto<any>;

    @observable payments_entityAsk!: PaymentAskDto;
    @observable payments_entityCreate!: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable payments_entityEdit!: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable payments_entityDelete!: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable payments_entityReturn!: PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable payments_entitySummary!: PagedResultDto<any>;
    @observable payments_entities!: PagedResultDto<any>;
    @observable payments_entitiesList!: PagedResultDto<any>;
    @observable payments_entitySummaryCondensed!: PagedResultDto<any>;

    @observable people_entityAsk!: PeopleAskDto;
    @observable people_entityCreate!: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable people_entityEdit!: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable people_entityDelete!: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable people_entityReturn!: PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable people_entitySummary!: PagedResultDto<any>;
    @observable people_entities!: PagedResultDto<any>;
    @observable people_entitiesList!: PagedResultDto<any>;
    @observable people_entitySummaryCondensed!: PagedResultDto<any>;

    @observable reports_entityAsk!: ReportAskDto;
    @observable reports_entityCreate!: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable reports_entityEdit!: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable reports_entityDelete!: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable reports_entityReturn!: ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable reports_entitySummary!: PagedResultDto<any>;
    @observable reports_entities!: PagedResultDto<any>;
    @observable reports_entitiesList!: PagedResultDto<any>;
    @observable reports_entitySummaryCondensed!: PagedResultDto<any>;

    @observable scheduling_entityAsk!: SchedulingAskDto;
    @observable scheduling_entityCreate!: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable scheduling_entityEdit!: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable scheduling_entityDelete!: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable scheduling_entityReturn!: SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable scheduling_entitySummary!: PagedResultDto<any>;
    @observable scheduling_entities!: PagedResultDto<any>;
    @observable scheduling_entitiesList!: PagedResultDto<any>;
    @observable scheduling_entitySummaryCondensed!: PagedResultDto<any>;

    @observable teams_entityAsk!: TeamAskDto;
    @observable teams_entityCreate!: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable teams_entityEdit!: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable teams_entityDelete!: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable teams_entityReturn!: TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable teams_entitySummary!: PagedResultDto<any>;
    @observable teams_entities!: PagedResultDto<any>;
    @observable teams_entitiesList!: PagedResultDto<any>;
    @observable teams_entitySummaryCondensed!: PagedResultDto<any>;

    @observable workOrders_entityAsk!: WorkOrderAskDto;
    @observable workOrders_entityCreate!: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable workOrders_entityEdit!: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable workOrders_entityDelete!: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable workOrders_entityReturn!: WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable workOrders_entitySummary!: PagedResultDto<any>;
    @observable workOrders_entities!: PagedResultDto<any>;
    @observable workOrders_entitiesList!: PagedResultDto<any>;
    @observable workOrders_entitySummaryCondensed!: PagedResultDto<any>;

    //--------------------------------------------------------------------------

    @observable areaChart_Configuration!: AreaChart_Configuration<AreaChart_DataModel, AxisModel>;
    @observable waterfallChart_Configuration!: WaterfallChart_Configuration<WaterfallChart_DataModel>;
    @observable violinPlot_Configuration!: ViolinPlot_Configuration<ViolinPlot_DataModel>;
    @observable vennPlot_Configuration!: VennPlot_Configuration<VennPlot_DataModel>;
    @observable sunburstPlot_Configuration!: SunburstPlot_Configuration<SunburstPlot_DataModel>;
    @observable stockPlot_Configuration!: StockPlot_Configuration<StockPlot_DataModel>;
    @observable scatterPlot_Configuration!: ScatterPlot_Configuration<ScatterPlot_DataModel>;
    @observable sankeyPlot_Configuration!: SankeyPlot_Configuration<SankeyPlot_DataModel>;
    @observable roseChart_Configuration!: RoseChart_Configuration<RoseChart_DataModel>;
    @observable ring_Configuration!: Ring_Configuration;
    @observable radialBarChart_Configuration!: RadialBarChart_Configuration<RadialBarChart_DataModel>;
    @observable radarChart_Configuration!: RadarChart_Configuration;
    @observable progress_Configuration!: Progress_Configuration;
    @observable pieChart_Configuration!: PieChart_Configuration<PieChart_DataModel>;
    @observable multiLineChart_Configuration!: MultiLineChart_Configuration<MultiLineChart_DataModel>;
    @observable liquid_Configuration!: Liquid_Configuration;
    @observable lineChart_Configuration!: LineChart_Configuration<LineChart_DataModel>;
    @observable histogram_Configuration!: Histogram_Configuration<Histogram_DataModel>;
    @observable heatMap_Configuration!: HeatMap_Configuration<HeatMap_DataModel>;
    @observable gauge_Configuration!: Gauge_Configuration;
    @observable funnelChart_Configuration!: FunnelChart_Configuration<FunnelChart_DataModel>;
    @observable donutChart_Configuration!: DonutChart_Configuration<DonutChart_DataModel>;
    @observable columnGraph_Configuration!: ColumnGraph_Configuration<ColumnGraph_DataModel>;
    @observable circlePacking_Configuration!: CirclePacking_Configuration<CirclePacking_DataModel<CirclePacking_DataModelChildren>>;
    @observable bullet_Configuration!: Bullet_Configuration<Bullet_DataModel>;
    @observable barChart_Configuration!: BarChart_Configuration<BarChart_DataModel>;
    @observable boxPlot_Configuration!: BoxPlot_Configuration<BoxPlot_DataModel, BoxStyleModel>;

    //-------------------------------------------------------------------------- 

    @observable communication_multiEntityList!: PagedResultDto<any>;

    @observable communication_entityOptionSummary!: PagedResultDto<any>;
    @observable communication_entityOptions!: PagedResultDto<any>;
    @observable communication_optionsList!: PagedResultDto<any>;

    @observable communication_entityStateSummary!: PagedResultDto<any>;
    @observable communication_entityStates!: PagedResultDto<any>;
    @observable communication_statesList!: PagedResultDto<any>;

    @observable communication_entityItemSummary!: PagedResultDto<any>;
    @observable communication_entityItems!: PagedResultDto<any>;
    @observable communication_itemsList!: PagedResultDto<any>;
    @observable communication_itemOptionsCache!: PagedResultDto<any>;

    @observable communication_entityTypeSummary!: PagedResultDto<any>;
    @observable communication_entityTypes!: PagedResultDto<any>;
    @observable communication_typesList!: PagedResultDto<any>;
    @observable communication_typeItemsCache!: PagedResultDto<any>;
    @observable communication_typeStatesCache!: PagedResultDto<any>;
    @observable communication_typeItemsEdit!: PagedResultDto<any>;
    @observable communication_typeStatesEdit!: PagedResultDto<any>;

    @observable communication_entityTemplateSummary!: PagedResultDto<any>;
    @observable communication_entityTemplates!: PagedResultDto<any>;
    @observable communication_templatesList!: PagedResultDto<any>;
    @observable communication_templateItems!: PagedResultDto<any>;
    @observable communication_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable communication_templateRecordCache!: Array<TemplateRecordDto>;

    @observable communication_defaultValues!: FormGridDto[];
    @observable communication_valuesCache!: FormGridDto[];

    //-------------------------------------------------------------------------- 

    @observable integrations_multiEntityList!: PagedResultDto<any>;

    @observable integrations_entityOptionSummary!: PagedResultDto<any>;
    @observable integrations_entityOptions!: PagedResultDto<any>;
    @observable integrations_optionsList!: PagedResultDto<any>;

    @observable integrations_entityStateSummary!: PagedResultDto<any>;
    @observable integrations_entityStates!: PagedResultDto<any>;
    @observable integrations_statesList!: PagedResultDto<any>;

    @observable integrations_entityItemSummary!: PagedResultDto<any>;
    @observable integrations_entityItems!: PagedResultDto<any>;
    @observable integrations_itemsList!: PagedResultDto<any>;
    @observable integrations_itemOptionsCache!: PagedResultDto<any>;

    @observable integrations_entityTypeSummary!: PagedResultDto<any>;
    @observable integrations_entityTypes!: PagedResultDto<any>;
    @observable integrations_typesList!: PagedResultDto<any>;
    @observable integrations_typeItemsCache!: PagedResultDto<any>;
    @observable integrations_typeStatesCache!: PagedResultDto<any>;
    @observable integrations_typeItemsEdit!: PagedResultDto<any>;
    @observable integrations_typeStatesEdit!: PagedResultDto<any>;

    @observable integrations_entityTemplateSummary!: PagedResultDto<any>;
    @observable integrations_entityTemplates!: PagedResultDto<any>;
    @observable integrations_templatesList!: PagedResultDto<any>;
    @observable integrations_templateItems!: PagedResultDto<any>;
    @observable integrations_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable integrations_templateRecordCache!: Array<TemplateRecordDto>;

    @observable integrations_defaultValues!: FormGridDto[];
    @observable integrations_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------


    @observable inventory_multiEntityList!: PagedResultDto<any>;

    @observable inventory_entityOptionSummary!: PagedResultDto<any>;
    @observable inventory_entityOptions!: PagedResultDto<any>;
    @observable inventory_optionsList!: PagedResultDto<any>;

    @observable inventory_entityStateSummary!: PagedResultDto<any>;
    @observable inventory_entityStates!: PagedResultDto<any>;
    @observable inventory_statesList!: PagedResultDto<any>;

    @observable inventory_entityItemSummary!: PagedResultDto<any>;
    @observable inventory_entityItems!: PagedResultDto<any>;
    @observable inventory_itemsList!: PagedResultDto<any>;
    @observable inventory_itemOptionsCache!: PagedResultDto<any>;

    @observable inventory_entityTypeSummary!: PagedResultDto<any>;
    @observable inventory_entityTypes!: PagedResultDto<any>;
    @observable inventory_typesList!: PagedResultDto<any>;
    @observable inventory_typeItemsCache!: PagedResultDto<any>;
    @observable inventory_typeStatesCache!: PagedResultDto<any>;
    @observable inventory_typeItemsEdit!: PagedResultDto<any>;
    @observable inventory_typeStatesEdit!: PagedResultDto<any>;

    @observable inventory_entityTemplateSummary!: PagedResultDto<any>;
    @observable inventory_entityTemplates!: PagedResultDto<any>;
    @observable inventory_templatesList!: PagedResultDto<any>;
    @observable inventory_templateItems!: PagedResultDto<any>;
    @observable inventory_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable templateRecordCache!: Array<TemplateRecordDto>;

    @observable inventory_defaultValues!: FormGridDto[];
    @observable inventory_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable menu_multiEntityList!: PagedResultDto<any>;

    @observable menu_entityOptionSummary!: PagedResultDto<any>;
    @observable menu_entityOptions!: PagedResultDto<any>;
    @observable menu_optionsList!: PagedResultDto<any>;

    @observable menu_entityStateSummary!: PagedResultDto<any>;
    @observable menu_entityStates!: PagedResultDto<any>;
    @observable menu_statesList!: PagedResultDto<any>;

    @observable menu_entityItemSummary!: PagedResultDto<any>;
    @observable menu_entityItems!: PagedResultDto<any>;
    @observable menu_itemsList!: PagedResultDto<any>;
    @observable menu_itemOptionsCache!: PagedResultDto<any>;

    @observable menu_entityTypeSummary!: PagedResultDto<any>;
    @observable menu_entityTypes!: PagedResultDto<any>;
    @observable menu_typesList!: PagedResultDto<any>;
    @observable menu_typeItemsCache!: PagedResultDto<any>;
    @observable menu_typeStatesCache!: PagedResultDto<any>;
    @observable menu_typeItemsEdit!: PagedResultDto<any>;
    @observable menu_typeStatesEdit!: PagedResultDto<any>;

    @observable menu_entityTemplateSummary!: PagedResultDto<any>;
    @observable menu_entityTemplates!: PagedResultDto<any>;
    @observable menu_templatesList!: PagedResultDto<any>;
    @observable menu_templateItems!: PagedResultDto<any>;
    @observable menu_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable menu_templateRecordCache!: Array<TemplateRecordDto>;

    @observable menu_defaultValues!: FormGridDto[];
    @observable menu_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable payments_multiEntityList!: PagedResultDto<any>;

    @observable payments_entityOptionSummary!: PagedResultDto<any>;
    @observable payments_entityOptions!: PagedResultDto<any>;
    @observable payments_optionsList!: PagedResultDto<any>;

    @observable payments_entityStateSummary!: PagedResultDto<any>;
    @observable payments_entityStates!: PagedResultDto<any>;
    @observable payments_statesList!: PagedResultDto<any>;

    @observable payments_entityItemSummary!: PagedResultDto<any>;
    @observable payments_entityItems!: PagedResultDto<any>;
    @observable payments_itemsList!: PagedResultDto<any>;
    @observable payments_itemOptionsCache!: PagedResultDto<any>;

    @observable payments_entityTypeSummary!: PagedResultDto<any>;
    @observable payments_entityTypes!: PagedResultDto<any>;
    @observable payments_typesList!: PagedResultDto<any>;
    @observable payments_typeItemsCache!: PagedResultDto<any>;
    @observable payments_typeStatesCache!: PagedResultDto<any>;
    @observable payments_typeItemsEdit!: PagedResultDto<any>;
    @observable payments_typeStatesEdit!: PagedResultDto<any>;

    @observable payments_entityTemplateSummary!: PagedResultDto<any>;
    @observable payments_entityTemplates!: PagedResultDto<any>;
    @observable payments_templatesList!: PagedResultDto<any>;
    @observable payments_templateItems!: PagedResultDto<any>;
    @observable payments_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable payments_templateRecordCache!: Array<TemplateRecordDto>;

    @observable payments_defaultValues!: FormGridDto[];
    @observable payments_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable people_multiEntityList!: PagedResultDto<any>;

    @observable people_entityOptionSummary!: PagedResultDto<any>;
    @observable people_entityOptions!: PagedResultDto<any>;
    @observable people_optionsList!: PagedResultDto<any>;

    @observable people_entityStateSummary!: PagedResultDto<any>;
    @observable people_entityStates!: PagedResultDto<any>;
    @observable people_statesList!: PagedResultDto<any>;

    @observable people_entityItemSummary!: PagedResultDto<any>;
    @observable people_entityItems!: PagedResultDto<any>;
    @observable people_itemsList!: PagedResultDto<any>;
    @observable people_itemOptionsCache!: PagedResultDto<any>;

    @observable people_entityTypeSummary!: PagedResultDto<any>;
    @observable people_entityTypes!: PagedResultDto<any>;
    @observable people_typesList!: PagedResultDto<any>;
    @observable people_typeItemsCache!: PagedResultDto<any>;
    @observable people_typeStatesCache!: PagedResultDto<any>;
    @observable people_typeItemsEdit!: PagedResultDto<any>;
    @observable people_typeStatesEdit!: PagedResultDto<any>;

    @observable people_entityTemplateSummary!: PagedResultDto<any>;
    @observable people_entityTemplates!: PagedResultDto<any>;
    @observable people_templatesList!: PagedResultDto<any>;
    @observable people_templateItems!: PagedResultDto<any>;
    @observable people_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable people_templateRecordCache!: Array<TemplateRecordDto>;

    @observable people_defaultValues!: FormGridDto[];
    @observable people_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable reports_multiEntityList!: PagedResultDto<any>;

    @observable reports_entityOptionSummary!: PagedResultDto<any>;
    @observable reports_entityOptions!: PagedResultDto<any>;
    @observable reports_optionsList!: PagedResultDto<any>;

    @observable reports_entityStateSummary!: PagedResultDto<any>;
    @observable reports_entityStates!: PagedResultDto<any>;
    @observable reports_statesList!: PagedResultDto<any>;

    @observable reports_entityItemSummary!: PagedResultDto<any>;
    @observable reports_entityItems!: PagedResultDto<any>;
    @observable reports_itemsList!: PagedResultDto<any>;
    @observable reports_itemOptionsCache!: PagedResultDto<any>;

    @observable reports_entityTypeSummary!: PagedResultDto<any>;
    @observable reports_entityTypes!: PagedResultDto<any>;
    @observable reports_typesList!: PagedResultDto<any>;
    @observable reports_typeItemsCache!: PagedResultDto<any>;
    @observable reports_typeStatesCache!: PagedResultDto<any>;
    @observable reports_typeItemsEdit!: PagedResultDto<any>;
    @observable reports_typeStatesEdit!: PagedResultDto<any>;

    @observable reports_entityTemplateSummary!: PagedResultDto<any>;
    @observable reports_entityTemplates!: PagedResultDto<any>;
    @observable reports_templatesList!: PagedResultDto<any>;
    @observable reports_templateItems!: PagedResultDto<any>;
    @observable reports_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable reports_templateRecordCache!: Array<TemplateRecordDto>;

    @observable reports_defaultValues!: FormGridDto[];
    @observable reports_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable scheduling_multiEntityList!: PagedResultDto<any>;

    @observable scheduling_entityOptionSummary!: PagedResultDto<any>;
    @observable scheduling_entityOptions!: PagedResultDto<any>;
    @observable scheduling_optionsList!: PagedResultDto<any>;

    @observable scheduling_entityStateSummary!: PagedResultDto<any>;
    @observable scheduling_entityStates!: PagedResultDto<any>;
    @observable scheduling_statesList!: PagedResultDto<any>;

    @observable scheduling_entityItemSummary!: PagedResultDto<any>;
    @observable scheduling_entityItems!: PagedResultDto<any>;
    @observable scheduling_itemsList!: PagedResultDto<any>;
    @observable scheduling_itemOptionsCache!: PagedResultDto<any>;

    @observable scheduling_entityTypeSummary!: PagedResultDto<any>;
    @observable scheduling_entityTypes!: PagedResultDto<any>;
    @observable scheduling_typesList!: PagedResultDto<any>;
    @observable scheduling_typeItemsCache!: PagedResultDto<any>;
    @observable scheduling_typeStatesCache!: PagedResultDto<any>;
    @observable scheduling_typeItemsEdit!: PagedResultDto<any>;
    @observable scheduling_typeStatesEdit!: PagedResultDto<any>;

    @observable scheduling_entityTemplateSummary!: PagedResultDto<any>;
    @observable scheduling_entityTemplates!: PagedResultDto<any>;
    @observable scheduling_templatesList!: PagedResultDto<any>;
    @observable scheduling_templateItems!: PagedResultDto<any>;
    @observable scheduling_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable scheduling_templateRecordCache!: Array<TemplateRecordDto>;

    @observable scheduling_defaultValues!: FormGridDto[];
    @observable scheduling_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable teams_multiEntityList!: PagedResultDto<any>;

    @observable teams_entityOptionSummary!: PagedResultDto<any>;
    @observable teams_entityOptions!: PagedResultDto<any>;
    @observable teams_optionsList!: PagedResultDto<any>;

    @observable teams_entityStateSummary!: PagedResultDto<any>;
    @observable teams_entityStates!: PagedResultDto<any>;
    @observable teams_statesList!: PagedResultDto<any>;

    @observable teams_entityItemSummary!: PagedResultDto<any>;
    @observable teams_entityItems!: PagedResultDto<any>;
    @observable teams_itemsList!: PagedResultDto<any>;
    @observable teams_itemOptionsCache!: PagedResultDto<any>;

    @observable teams_entityTypeSummary!: PagedResultDto<any>;
    @observable teams_entityTypes!: PagedResultDto<any>;
    @observable teams_typesList!: PagedResultDto<any>;
    @observable teams_typeItemsCache!: PagedResultDto<any>;
    @observable teams_typeStatesCache!: PagedResultDto<any>;
    @observable teams_typeItemsEdit!: PagedResultDto<any>;
    @observable teams_typeStatesEdit!: PagedResultDto<any>;

    @observable teams_entityTemplateSummary!: PagedResultDto<any>;
    @observable teams_entityTemplates!: PagedResultDto<any>;
    @observable teams_templatesList!: PagedResultDto<any>;
    @observable teams_templateItems!: PagedResultDto<any>;
    @observable teams_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable teams_templateRecordCache!: Array<TemplateRecordDto>;

    @observable teams_defaultValues!: FormGridDto[];
    @observable teams_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable workOrders_multiEntityList!: PagedResultDto<any>;

    @observable workOrders_entityOptionSummary!: PagedResultDto<any>;
    @observable workOrders_entityOptions!: PagedResultDto<any>;
    @observable workOrders_optionsList!: PagedResultDto<any>;

    @observable workOrders_entityStateSummary!: PagedResultDto<any>;
    @observable workOrders_entityStates!: PagedResultDto<any>;
    @observable workOrders_statesList!: PagedResultDto<any>;

    @observable workOrders_entityItemSummary!: PagedResultDto<any>;
    @observable workOrders_entityItems!: PagedResultDto<any>;
    @observable workOrders_itemsList!: PagedResultDto<any>;
    @observable workOrders_itemOptionsCache!: PagedResultDto<any>;

    @observable workOrders_entityTypeSummary!: PagedResultDto<any>;
    @observable workOrders_entityTypes!: PagedResultDto<any>;
    @observable workOrders_typesList!: PagedResultDto<any>;
    @observable workOrders_typeItemsCache!: PagedResultDto<any>;
    @observable workOrders_typeStatesCache!: PagedResultDto<any>;
    @observable workOrders_typeItemsEdit!: PagedResultDto<any>;
    @observable workOrders_typeStatesEdit!: PagedResultDto<any>;

    @observable workOrders_entityTemplateSummary!: PagedResultDto<any>;
    @observable workOrders_entityTemplates!: PagedResultDto<any>;
    @observable workOrders_templatesList!: PagedResultDto<any>;
    @observable workOrders_templateItems!: PagedResultDto<any>;
    @observable workOrders_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable workOrders_templateRecordCache!: Array<TemplateRecordDto>;

    @observable workOrders_defaultValues!: FormGridDto[];
    @observable workOrders_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    //Stored as a group, filtered on retrieval, maybe via State within EntityTabView
    @observable cards_CommentData!: Array<any>;
    @observable cards_BodyData!: Array<any>;
    @observable cards_HeaderData!: Array<any>;
    @observable cards_TagData!: Array<any>;

    @observable cards_BodyConfig!: Array<any>;
    @observable cards_HeaderConfig!: Array<any>;
    @observable cards_TagConfig!: Array<any>;

    //--------------------------------------------------------------------------

    @observable defaultEntityImages!: Array<UploadFile>;
    @observable defaultEntityFiles!: Array<UploadFile>;
    @observable fileCache!: EntityCommitContainer;

    //--------------------------------------------------------------------------

    @observable entitySummaryList!: Array<any>;
    @observable templateSummaryList!: Array<any>;
    @observable typeSummaryList!: Array<any>;
    @observable fieldSummaryList!: Array<any>;
    @observable statesSummaryList!: Array<any>;
    @observable peopleSummaryList!: Array<any>;
    @observable teamSummaryList!: Array<any>;
    @observable communicationSummaryList!: Array<any>;

    //--------------------------------------------------------------------------

    @observable profileID: number = 0;
    @observable entityID: number = 0;
    @observable entityType: number = 0;
    @observable currentState: number = 0;
    @observable defaultEntityNotes!: Array<NoteDto>;
    @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;
    @observable currentNotifications: NotificationDto[] = [];
    @observable entityNotesCache!: Array<NoteDto>;
    @observable entityNoteRecordsCache!: Array<NoteRecordDto>;
    @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

    @observable users!: PagedResultDto<UserListSummaryDto>;
    @observable defaultUserList!: Array<UserListSummaryDto>;
    @observable userListCache!: Array<UserListSummaryDto>;
    @observable userListSummary!: PagedResultDto<UserListSummaryDto>;

    @observable users_Security!: PagedResultDto<UserListSummaryDto>;
    @observable defaultUserList_Security!: Array<UserListSummaryDto>;
    @observable userListCache_Security!: Array<UserListSummaryDto>;
    @observable userListSummary_Security!: PagedResultDto<UserListSummaryDto>;

    @observable docs!: PagedResultDto<any>;
    @observable defaultDocsList!: Array<any>;
    @observable docListCache!: Array<any>;
    @observable docListSummary!: PagedResultDto<any>;

    @observable persons!: PagedResultDto<PersonListSummaryDto>;
    @observable defaultPersonList!: Array<PersonListSummaryDto>;
    @observable personListCache!: Array<PersonListSummaryDto>;
    @observable personListSummary!: PagedResultDto<PersonListSummaryDto>;

    @observable persons_Security!: PagedResultDto<PersonListSummaryDto>;
    @observable defaultPersonList_Security!: Array<PersonListSummaryDto>;
    @observable personListCache_Security!: Array<PersonListSummaryDto>;
    @observable personListSummary_Security!: PagedResultDto<PersonListSummaryDto>;

    @observable teams!: PagedResultDto<TeamListSummaryDto>;
    @observable defaultTeamList!: Array<TeamListSummaryDto>;
    @observable teamListCache!: Array<TeamListSummaryDto>;
    @observable teamListSummary!: PagedResultDto<TeamListSummaryDto>;

    @observable teams_Security!: PagedResultDto<TeamListSummaryDto>;
    @observable defaultTeamList_Security!: Array<TeamListSummaryDto>;
    @observable teamListCache_Security!: Array<TeamListSummaryDto>;
    @observable teamListSummary_Security!: PagedResultDto<TeamListSummaryDto>;

    @observable communication!: PagedResultDto<CommunicationListSummaryDto>;
    @observable defaultCommunicationList!: Array<CommunicationListSummaryDto>;
    @observable communicationListCache!: Array<CommunicationListSummaryDto>;
    @observable communicationListSummary!: PagedResultDto<CommunicationListSummaryDto>;

    @observable inventory!: PagedResultDto<InventoryListSummaryDto>;
    @observable defaultInventoryList!: Array<InventoryListSummaryDto>;
    @observable inventoryListCache!: Array<InventoryListSummaryDto>;
    @observable inventoryListSummary!: PagedResultDto<InventoryListSummaryDto>;

    @observable menus!: PagedResultDto<MenuListSummaryDto>;
    @observable defaultMenuList!: Array<MenuListSummaryDto>;
    @observable menuListCache!: Array<MenuListSummaryDto>;
    @observable menuListSummary!: PagedResultDto<MenuListSummaryDto>;

    @observable payments!: PagedResultDto<PaymentsListSummaryDto>;
    @observable defaultPaymentsList!: Array<PaymentsListSummaryDto>;
    @observable paymentsListCache!: Array<PaymentsListSummaryDto>;
    @observable paymentsListSummary!: PagedResultDto<PaymentsListSummaryDto>;

    @observable reports!: PagedResultDto<ReportsListSummaryDto>;
    @observable defaultReportsList!: Array<ReportsListSummaryDto>;
    @observable reportsListCache!: Array<ReportsListSummaryDto>;
    @observable reportsListSummary!: PagedResultDto<ReportsListSummaryDto>;

    @observable schedules!: PagedResultDto<SchedulingListSummaryDto>;
    @observable defaultSchedulesList!: Array<SchedulingListSummaryDto>;
    @observable schedulesListCache!: Array<SchedulingListSummaryDto>;
    @observable schedulesListSummary!: PagedResultDto<SchedulingListSummaryDto>;

    @observable workOrders!: PagedResultDto<WorkOrderListSummaryDto>;
    @observable defaultWorkOrdersList!: Array<WorkOrderListSummaryDto>;
    @observable workOrdersListCache!: Array<WorkOrderListSummaryDto>;
    @observable workOrdersListSummary!: PagedResultDto<WorkOrderListSummaryDto>;

    @observable rolesSummary!: PagedResultDto<GetAllRoleOutput>;
    @observable usersearch_Mentions: any[] = [];
    @observable usersearch_Hashtags: any[] = [];
    @observable personsearch_Mentions: any[] = [];
    @observable personrsearch_Hashtags: any[] = [];
    @observable docsearch_Mentions: any[] = [];
    @observable docsearch_Hashtags: any[] = [];
    @observable teamsearch_Mentions: any[] = [];
    @observable teamsearch_Hashtags: any[] = [];
    @observable communicationsearch_Mentions: any[] = [];
    @observable communicationsearch_Hashtags: any[] = [];
    @observable inventorysearch_Mentions: any[] = [];
    @observable inventorysearch_Hashtags: any[] = [];
    @observable menusearch_Mentions: any[] = [];
    @observable menusearch_Hashtags: any[] = [];
    @observable paymentsearch_Mentions: any[] = [];
    @observable paymentearch_Hashtags: any[] = [];
    @observable reportsearch_Mentions: any[] = [];
    @observable reportsearch_Hashtags: any[] = [];
    @observable schedulingsearch_Mentions: any[] = [];
    @observable schedulingsearch_Hashtags: any[] = [];
    @observable workordersearch_Mentions: any[] = [];
    @observable workordersearch_Hashtags: any[] = [];
    @observable itemsearch_Mentions: any[] = [];
    @observable optionsearch_Mentions: any[] = [];
    @observable statesearch_Mentions: any[] = [];
    @observable templatesearch_Mentions: any[] = [];
    @observable typesearch_Mentions: any[] = [];
    @observable itemsearch_Hashtags: any[] = [];
    @observable optionsearch_Hashtags: any[] = [];
    @observable statesearch_Hashtags: any[] = [];
    @observable templatesearch_Hashtags: any[] = [];
    @observable typesearch_Hashtags: any[] = [];

    @observable communication_entitysearch_Hashtags: any[] = [];
    @observable communication_entitysearch_Mentions: any[] = [];
    @observable integrations_entitysearch_Hashtags: any[] = [];
    @observable integrations_entitysearch_Mentions: any[] = [];
    @observable inventory_entitysearch_Hashtags: any[] = [];
    @observable inventory_entitysearch_Mentions: any[] = [];
    @observable menu_entitysearch_Hashtags: any[] = [];
    @observable menu_entitysearch_Mentions: any[] = [];
    @observable payments_entitysearch_Hashtags: any[] = [];
    @observable payments_entitysearch_Mentions: any[] = [];
    @observable people_entitysearch_Hashtags: any[] = [];
    @observable people_entitysearch_Mentions: any[] = [];
    @observable reports_entitysearch_Hashtags: any[] = [];
    @observable reports_entitysearch_Mentions: any[] = [];
    @observable scheduling_entitysearch_Hashtags: any[] = [];
    @observable scheduling_entitysearch_Mentions: any[] = [];
    @observable teams_entitysearch_Hashtags: any[] = [];
    @observable teams_entitysearch_Mentions: any[] = [];
    @observable workOrders_entitysearch_Hashtags: any[] = [];
    @observable workOrders_entitysearch_Mentions: any[] = [];

    //--------------------------------------------------------------------------

    @action
    async menu_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.menu_entityReturn = await menuService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });
 
    }

    @action
    async menu_updateItemsList(updateMemberList: any) {
        this.menu_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async menu_updateStatesList(updateMemberList: any) {
        this.menu_typeStatesEdit.items.push(updateMemberList);
    }


    @action
    async communication_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.communication_entityReturn = await communicationService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });



    }

    @action
    async communication_updateItemsList(updateMemberList: any) {
        this.communication_typeItemsEdit.items.push(updateMemberList);
    }


    @action
    async communication_updateStatesList(updateMemberList: any) {
        this.communication_typeStatesEdit.items.push(updateMemberList);
    }
















    @action
    async initReportConfiguration(type: number) {

        if (type === DashboardItemTypes.AreaChart) {
            this.areaChart_Configuration =
            {
                data: [],
                xField: '',
                yField: '',
                xAxis: {
                    range: [0, 0],
                    scale: 0
                },
                fill: '',
            };
        }
        else if (type === DashboardItemTypes.BarChart) {
            this.barChart_Configuration = {
                data: [],
                xField: '',
                yField: '',
                seriesField: '',
            };
        }
        else if (type === DashboardItemTypes.BoxPlot) {
            this.boxPlot_Configuration = {
                data: [],
                width: 0,
                height: 0,
                xField: '0',
                yField: ['low', 'q1', 'median', 'q3', 'high'],
                boxStyle: {
                    stroke: '',
                    fill: '',
                    fillOpacity: 0
                }
            };
        }
        else if (type === DashboardItemTypes.BubbleGraph) {
            // Not Available
        }
        else if (type === DashboardItemTypes.Bullet) {
            this.bullet_Configuration = {
                data: [],
                measureField: 'measures',
                rangeField: 'ranges',
                targetField: 'target',
                xField: 'title',
                color: {
                    range: '#f0efff',
                    measure: '#5B8FF9',
                    target: '#3D76DD',
                },
                xAxis: {
                    line: null,
                },
                yAxis: {},
                legend: {}
            };
        }
        else if (type === DashboardItemTypes.Button) {
            // Not Available
        }
        else if (type === DashboardItemTypes.CirclePacking) {
            this.circlePacking_Configuration = {
                autoFit: true,
                data: {
                    name: '',
                    children: []
                },
            };
        }
        else if (type === DashboardItemTypes.ColumnGraph) {
            this.columnGraph_Configuration = {
                data: [],
                xField: '',
                yField: '',
                label: {
                    position: 'middle',
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                },
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                },
                meta: {
                    type: {
                        alias: '',
                    },
                    sales: {
                        alias: '',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.DataGrid) {
            // Not Available
        }
        else if (type === DashboardItemTypes.DonutChart) {
            this.donutChart_Configuration = {
                appendPadding: 10,
                data: [],
                angleField: 'value',
                colorField: 'type',
                radius: 1,
                innerRadius: 0.6,
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{value}',
                    style: {
                        textAlign: 'center',
                        fontSize: '1em'
                    },
                },
                interactions: [
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                ],
                statistic: {
                    title: false,
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '1em'
                        },
                        content: 'Plot',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.FunnelChart) {
            this.funnelChart_Configuration = {
                data: [],
                xField: 'stage',
                yField: 'number',
            };
        }
        else if (type === DashboardItemTypes.Gauge) {
            this.gauge_Configuration = {

                percent: 0.75,
                range: {
                    color: '#30BF78',
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                    pin: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                },
                axis: {
                    label: {

                    },
                    subTickLine: {
                        count: 3,
                    },
                },
                statistic: {
                    content: {

                    },
                },
            };
        }
        else if (type === DashboardItemTypes.HeatMap) {
            this.heatMap_Configuration = {
                data: [],
                xField: 'name',
                yField: 'country',
                colorField: 'value',
                shape: 'circle',
                sizeRatio: 0.5,
                color: ['#0d5fbb', '#7eadfc', '#0d5fbb', '#7eadfc'],
                label: {
                    style: {
                        fill: '#fff',
                        shadowBlur: 2,
                        shadowColor: 'rgba(0, 0, 0, .45)',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.Histogram) {
            this.histogram_Configuration = {
                data: [],
                binField: 'value',
                binWidth: 2,
            }
                ;
        }
        else if (type === DashboardItemTypes.LineChart) {
            this.lineChart_Configuration = {
                data: [],
                padding: 'auto',
                xField: 'Date',
                yField: 'scales',
                annotations: [
                    {
                        type: 'regionFilter',
                        start: ['min', 'median'],
                        end: ['max', '0'],
                        color: '#F4664A',
                    },
                    {
                        type: 'text',
                        position: ['min', 'median'],
                        content: '中位数',
                        offsetY: -4,
                        style: {
                            textBaseline: 'bottom',
                        },
                    },
                    {
                        type: 'line',
                        start: ['min', 'median'],
                        end: ['max', 'median'],
                        style: {
                            stroke: '#F4664A',
                            lineDash: [2, 2],
                        },
                    },
                ],
            }
        }
        else if (type === DashboardItemTypes.Liquid) {
            this.liquid_Configuration = {
                percent: 0.25,
                outline: {
                    border: 4,
                    distance: 8,
                },
                wave: {
                    length: 128,
                },
            };
        }
        else if (type === DashboardItemTypes.MultiLineChart) {
            this.multiLineChart_Configuration = {
                data: [],
                xField: 'year',
                yField: 'value',
                seriesField: 'category',
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    label: {

                    },
                },
            };
        }
        else if (type === DashboardItemTypes.PieChart) {
            this.pieChart_Configuration = {
                appendPadding: 10,
                data: [],
                angleField: 'value',
                colorField: 'type',
                radius: 0.9,
                label: {
                    type: 'inner',
                    offset: '-30%',
                    style: {
                        fontSize: 14,
                        textAlign: 'center',
                    },
                },
                interactions: [{ type: 'element-active' }],
            };
        }
        else if (type === DashboardItemTypes.Progress) {
            this.progress_Configuration = {
                height: 100,
                width: 300,
                autoFit: false,
                percent: 0.536,
                barWidthRatio: 0.3,
                color: ['#F4664A', '#E8EDF3'],
            };
        }
        else if (type === DashboardItemTypes.RadarChart) {
            this.radarChart_Configuration = {
                data: [],
                xField: 'name',
                yField: 'star',
                appendPadding: [0, 10, 0, 10],
                meta: {
                    star: {
                        alias: 'star',
                        min: 0,
                        nice: true,

                    },
                },
                xAxis: {
                    tickLine: null,
                },
                yAxis: {
                    label: false,
                    grid: {
                        alternateColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
                point: {
                    size: 2,
                },
                area: {},
            };
        }
        else if (type === DashboardItemTypes.RadialBarChart) {
            this.radialBarChart_Configuration = {
                data: [],
                colorField: 'star',
                barStyle: { lineCap: 'round' },
                xField: 'name',
                yField: 'star',
                radius: 0.8,
                innerRadius: 0.2,
                tooltip: {
                },
            };
        }
        else if (type === DashboardItemTypes.Ring) {
            this.ring_Configuration = {
                height: 100,
                width: 100,
                autoFit: false,
                percent: 0.7,
                color: ['#5B8FF9', '#E8EDF3'],
            };
        }
        else if (type === DashboardItemTypes.RoseChart) {
            this.roseChart_Configuration = {
                data: [],
                xField: 'type',
                yField: 'value',
                seriesField: 'type',
                radius: 0.9,
                legend: {
                    position: 'bottom',
                },
            };
        }
        else if (type === DashboardItemTypes.SankeyPlot) {
            this.sankeyPlot_Configuration = {

                data: [],
                sourceField: 'source',
                targetField: 'target',
                weightField: 'value',
                nodeWidthRatio: 0.008,
                nodePaddingRatio: 0.03,
            };
        }
        else if (type === DashboardItemTypes.ScatterPlot) {
            this.scatterPlot_Configuration = {
                appendPadding: 10,
                data: [],
                xField: 'Revenue (Millions)',
                yField: 'Rating',
                shape: 'circle',
                colorField: 'Genre',
                size: 4,
                yAxis: {
                    nice: true,
                    line: {
                        style: {
                            stroke: '#aaa',
                        },
                    },
                },
                xAxis: {
                    min: -100,
                    grid: {
                        line: {
                            style: {
                                stroke: '#eee',
                            },
                        },
                    },
                    line: {
                        style: {
                            stroke: '#aaa',
                        },
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.StockPlot) {
            this.stockPlot_Configuration = {
                data: [],
                xField: 'trade_date',
                yField: ['open', 'close', 'high', 'low'],
            };
        }
        else if (type === DashboardItemTypes.SunburstPlot) {
            this.sunburstPlot_Configuration = {
                data: [],
                innerRadius: 0.3,
                interactions: [
                    {
                        type: 'element-active',
                    },
                ],
                hierarchyConfig: {
                    field: 'sum',
                },
                drilldown: {
                    breadCrumb: {
                        rootText: '起始',
                    },
                },
            }
                ;
        }
        else if (type === DashboardItemTypes.VennPlot) {

        }
        else if (type === DashboardItemTypes.ViolinPlot) {
            this.violinPlot_Configuration = {
                height: 500,
                data: [],
                xField: 'x',
                yField: 'y',
            };
        }
        else if (type === DashboardItemTypes.WaterfallChart) {
            this.waterfallChart_Configuration = {
                data: [],
                xField: 'type',
                yField: 'money',
                appendPadding: [15, 0, 0, 0],
                meta: {
                    type: {
                        alias: 'Alias',
                    },
                    money: {

                    },
                },
                label: {
                    style: {
                        fontSize: 10,
                        fill: 'rgba(0,0,0,0.65)',
                    },
                    layout: [
                        {
                            type: 'interval-adjust-position',
                        },
                    ],
                },
                total: {
                    label: 'Label',
                    style: {
                        fill: '#96a6a6',
                    },
                },
            };
        }

        return;
    }

    @action
    async getReport(askDto: DashbordAskDto) {
        if (askDto.type === DashboardItemTypes.AreaChart) {
            let result = await dashboardService.getWidget_AreaChart(askDto);
            this.areaChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BarChart) {
            let result = await dashboardService.getWidget_BarChart(askDto);
            this.barChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BoxPlot) {
            let result = await dashboardService.getWidget_BoxPlot(askDto);
            this.boxPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BubbleGraph) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.Bullet) {
            let result = await dashboardService.getWidget_Bullet(askDto);
            this.bullet_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Button) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.CirclePacking) {
            let result = await dashboardService.getWidget_CirclePacking(askDto);
            this.circlePacking_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ColumnGraph) {
            let result = await dashboardService.getWidget_ColumnGraph(askDto);
            this.columnGraph_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.DataGrid) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.DonutChart) {
            let result = await dashboardService.getWidget_DonutChart(askDto);
            this.donutChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.FunnelChart) {
            let result = await dashboardService.getWidget_FunnelChart(askDto);
            this.funnelChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Gauge) {
            let result = await dashboardService.getWidget_Gauge(askDto);
            this.gauge_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.HeatMap) {
            let result = await dashboardService.getWidget_HeatMap(askDto);
            this.heatMap_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Histogram) {
            let result = await dashboardService.getWidget_Histogram(askDto);
            this.histogram_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.LineChart) {
            let result = await dashboardService.getWidget_LineChart(askDto);
            this.lineChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Liquid) {
            let result = await dashboardService.getWidget_Liquid(askDto);
            this.liquid_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.MultiLineChart) {
            let result = await dashboardService.getWidget_MultiLineChart(askDto);
            this.multiLineChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.PieChart) {
            let result = await dashboardService.getWidget_PieChart(askDto);
            this.pieChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Progress) {
            let result = await dashboardService.getWidget_Progress(askDto);
            this.progress_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RadarChart) {
            let result = await dashboardService.getWidget_RadarChart(askDto);
            this.radarChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RadialBarChart) {
            let result = await dashboardService.getWidget_RadialBarChart(askDto);
            this.radialBarChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Ring) {
            let result = await dashboardService.getWidget_Ring(askDto);
            this.ring_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RoseChart) {
            let result = await dashboardService.getWidget_RoseChart(askDto);
            this.roseChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.SankeyPlot) {
            let result = await dashboardService.getWidget_SankeyPlot(askDto);
            this.sankeyPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ScatterPlot) {
            let result = await dashboardService.getWidget_ScatterPlot(askDto);
            this.scatterPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.StockPlot) {
            let result = await dashboardService.getWidget_StockPlot(askDto);
            this.stockPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.SunburstPlot) {
            let result = await dashboardService.getWidget_SunburstPlot(askDto);
            this.sunburstPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.VennPlot) {
            let result = await dashboardService.getWidget_VennPlot(askDto);
            this.vennPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ViolinPlot) {
            let result = await dashboardService.getWidget_ViolinPlot(askDto);
            this.violinPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.WaterfallChart) {
            let result = await dashboardService.getWidget_WaterfallChart(askDto);
            this.waterfallChart_Configuration = result;
        }
        return;
    }

    //-------------------------------------------------

    @action
    async initUserDashboard() {
        this.user_Dashboard = {
            totalRows: 0,
            rows: []
        };
        return;
    }

    @action
    async initPublicDashboard() {
        this.public_Dashboard = {
            totalRows: 0,
            rows: []
        };
        return;
    }

    //-------------------------------------------------

    @action
    async getUserDashboard() {
        let result = await dashboardService.getUserDashboard();
        this.user_Dashboard = result;
        return;
    }

    @action
    async getPublicDashboard() {
        let result = await dashboardService.getPublicDashboard();
        this.public_Dashboard = result;
        return;
    }

    //-------------------------------------------------

    @action
    async initUpdateObject() {
        //console.warn('initUpdateObject: ' + dayjs(new Date()).toString())

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.userListCache_Security = [];
        this.userListSummary_Security = {
            totalCount: 0,
            items: []
        };

        this.entityNotesCache = [];
        this.entityNoteRecordsCache = [];
        this.defaultEntityNotes = [];

        this.defaultEntityNoteRecords = [];
        this.entityNotesSummary = {
            totalCount: 0,
            items: []
        }

        this.currentNotifications = [{
            id: 0,
            message: '',
            type: ''
        }];


    }

    @action
    async communication_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {
        //console.warn('communication_initUpdateObject: ' + dayjs(new Date()).toString())

        this.communication_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.communication_entityStates = {
            totalCount: 0,
            items: []
        };

        this.communication_entityItems = {
            totalCount: 0,
            items: []
        };

        this.communication_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.communication_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.communication_entityEdit.maxResultCount = maxResultCount;
        this.communication_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.communication_entityCreate.maxResultCount = maxResultCount;

        this.communication_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.communication_entityDelete.maxResultCount = maxResultCount;

        this.communication_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async integrations_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.integrations_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.integrations_entityStates = {
            totalCount: 0,
            items: []
        };

        this.integrations_entityItems = {
            totalCount: 0,
            items: []
        };

        this.integrations_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.integrations_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.integrations_entityEdit.maxResultCount = maxResultCount;
        this.integrations_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.integrations_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.integrations_entityDelete.maxResultCount = maxResultCount;

        this.integrations_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async inventory_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.inventory_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.inventory_entityStates = {
            totalCount: 0,
            items: []
        };

        this.inventory_entityItems = {
            totalCount: 0,
            items: []
        };

        this.inventory_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.inventory_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.inventory_entityEdit.maxResultCount = maxResultCount;
        this.inventory_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.inventory_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.inventory_entityDelete.maxResultCount = maxResultCount;

        this.inventory_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async menu_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {
        //console.warn('menu_initUpdateObject: ' + dayjs(new Date()).toString())

        this.menu_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.menu_entityStates = {
            totalCount: 0,
            items: []
        };

        this.menu_entityItems = {
            totalCount: 0,
            items: []
        };

        this.menu_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.menu_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.menu_entityEdit.maxResultCount = maxResultCount;
        this.menu_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.menu_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        //this.menu_entityDelete.maxResultCount = 500;

        this.menu_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async payments_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.payments_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.payments_entityStates = {
            totalCount: 0,
            items: []
        };

        this.payments_entityItems = {
            totalCount: 0,
            items: []
        };

        this.payments_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.payments_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.payments_entityEdit.maxResultCount = maxResultCount;
        this.payments_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.payments_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.payments_entityDelete.maxResultCount = maxResultCount;

        this.payments_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async people_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.people_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.people_entityStates = {
            totalCount: 0,
            items: []
        };

        this.people_entityItems = {
            totalCount: 0,
            items: []
        };

        this.people_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.people_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.people_entityEdit.maxResultCount = maxResultCount;
        this.people_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.people_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.people_entityDelete.maxResultCount = maxResultCount;

        this.people_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async reports_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.reports_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.reports_entityStates = {
            totalCount: 0,
            items: []
        };

        this.reports_entityItems = {
            totalCount: 0,
            items: []
        };

        this.reports_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.reports_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.reports_entityEdit.maxResultCount = maxResultCount;
        this.reports_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.reports_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.reports_entityDelete.maxResultCount = maxResultCount;

        this.reports_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async scheduling_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.scheduling_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.scheduling_entityStates = {
            totalCount: 0,
            items: []
        };

        this.scheduling_entityItems = {
            totalCount: 0,
            items: []
        };

        this.scheduling_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.scheduling_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.scheduling_entityEdit.maxResultCount = maxResultCount;
        this.scheduling_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.scheduling_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.scheduling_entityDelete.maxResultCount = maxResultCount;

        this.scheduling_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async teams_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.teams_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.teams_entityStates = {
            totalCount: 0,
            items: []
        };

        this.teams_entityItems = {
            totalCount: 0,
            items: []
        };

        this.teams_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.teams_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.teams_entityEdit.maxResultCount = maxResultCount;
        this.teams_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.teams_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.teams_entityDelete.maxResultCount = maxResultCount;

        this.teams_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async workOrders_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.workOrders_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.workOrders_entityStates = {
            totalCount: 0,
            items: []
        };

        this.workOrders_entityItems = {
            totalCount: 0,
            items: []
        };

        this.workOrders_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.workOrders_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.workOrders_entityEdit.maxResultCount = maxResultCount;
        this.workOrders_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.workOrders_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.workOrders_entityDelete.maxResultCount = maxResultCount;

        this.workOrders_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    //-------------------------------------------------

    @action
    async initFileStores() {
        //console.warn('initFileStores: ' + dayjs(new Date()).toString())

        this.defaultEntityImages = [];
        this.defaultEntityFiles = [];
        this.fileCache =
        {
            entityType: 0,
            entityID: 0,
            fileList: []
        };
    }

    @action
    async initCacheStores() {
        //console.warn('initCacheStores: ' + dayjs(new Date()).toString())

        this.communication_valuesCache = [];
        this.rolesSummary = {
            totalCount: 0,
            items: []
        };
        this.templateRecordCache = [];

        this.userListCache = [];
        this.defaultUserList = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        }
        this.userListSummary_Security = {
            totalCount: 0,
            items: []
        }

        this.personListSummary = {
            totalCount: 0,
            items: []
        }
        this.personListSummary_Security = {
            totalCount: 0,
            items: []
        }
        this.teamListSummary = {
            totalCount: 0,
            items: []
        }
        this.teamListSummary_Security = {
            totalCount: 0,
            items: []
        }

        this.communicationListCache = [];
        this.defaultCommunicationList = [];
        this.communicationListSummary = {
            totalCount: 0,
            items: []
        }

        this.inventoryListCache = [];
        this.defaultInventoryList = [];
        this.inventoryListSummary = {
            totalCount: 0,
            items: []
        }

        this.menuListCache = [];
        this.defaultMenuList = [];
        this.menuListSummary = {
            totalCount: 0,
            items: []
        }

        this.paymentsListCache = [];
        this.defaultPaymentsList = [];
        this.paymentsListSummary = {
            totalCount: 0,
            items: []
        }

        this.reportsListCache = [];
        this.defaultReportsList = [];
        this.reportsListSummary = {
            totalCount: 0,
            items: []
        }

        this.schedulesListCache = [];
        this.defaultSchedulesList = [];
        this.schedulesListSummary = {
            totalCount: 0,
            items: []
        }

        this.workOrdersListCache = [];
        this.defaultWorkOrdersList = [];
        this.workOrdersListSummary = {
            totalCount: 0,
            items: []
        }
        this.entitySummaryList = [];
        this.templateSummaryList = [];
        this.typeSummaryList = [];
        this.fieldSummaryList = [];
        this.peopleSummaryList = [];
        this.teamSummaryList = [];
    }

    @action
    async communication_initCacheStores() {
        //console.warn('communication_initCacheStores: ' + dayjs(new Date()).toString())

        this.communication_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.communication_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.communication_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.communication_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.communication_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async integrations_initCacheStores() {

        this.integrations_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async inventory_initCacheStores() {

        this.inventory_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async menu_initCacheStores() {
        //console.warn('menu_initCacheStores: ' + dayjs(new Date()).toString())

        this.menu_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.menu_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.menu_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.menu_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.menu_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async payments_initCacheStores() {

        this.payments_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.payments_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.payments_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.payments_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.payments_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async people_initCacheStores() {

        this.people_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.people_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.people_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.people_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.people_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async reports_initCacheStores() {

        this.reports_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.reports_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.reports_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.reports_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.reports_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async scheduling_initCacheStores() {

        this.scheduling_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async teams_initCacheStores() {

        this.teams_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.teams_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.teams_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.teams_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.teams_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async workOrders_initCacheStores() {

        this.workOrders_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    //-------------------------------------------------

    @action
    async communication_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {
        console.warn('communication_getEntitySummary: ' + dayjs(new Date()).toString())

        this.communication_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await communicationService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: 50,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        console.warn('communication_getEntitySummary - RETURNED: ' + dayjs(new Date()).toString())
        console.warn(JSON.stringify(entitySummary))

        const entityParse = entitySummary.entities;
        entityParse.map((entity: any) => {

 
            if (entity !== undefined) {  

                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.communication_entitySummary.items.push(entity);
                }
            }
        });
 
        //console.warn('communication_getEntitySummary - COMPLETE: ' + dayjs(new Date()).toString())
    }

    @action
    async integrations_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await integrationService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.integrations_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.integrations_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async inventory_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await inventoryService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.inventory_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.inventory_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async menu_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {
        //console.warn('menu_getEntitySummary: ' + dayjs(new Date()).toString())

        this.menu_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await menuService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });
 
        //console.warn('menu_getEntitySummary - RETURNED: ' + dayjs(new Date()).toString())

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                this.menu_entitySummary.items.push(entity);
                }
            }
        });

        //console.warn('menu_getEntitySummary - COMPLETE: ' + dayjs(new Date()).toString())
    }

    @action
    async payments_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await paymentService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.payments_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.payments_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async people_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await peopleService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.people_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.people_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async reports_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await reportService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.reports_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.reports_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async scheduling_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await schedulingService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.scheduling_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.scheduling_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async teams_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await teamService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.teams_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.teams_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    @action
    async workOrders_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        let entitySummary = await workOrderService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.workOrders_entitysearch_Hashtags = [];
        entitySummary.types.map((x: any) => {
            this.workOrders_entitysearch_Hashtags.push({
                id: x.id,
                relatedEntity: x.id,
                title: x.name
            });
        });
    }

    //-------------------------------------------------

}

export default DashboardStore;
