import { L } from "../../lib/abpUtility";

export const formItemTypes = {
    button: 0,
    datePicker: 1,
    entityGrid: 2,
    entityPicker: 3,
    fileUpload: 4,
    image: 5,
    multiSelect: 6,
    numberPicker: 7,
    profileImage: 8,
    searchSelect: 9,
    singleSelect: 10,
    switch: 11,
    textBox: 12, 
    paragraph: 13,
    approval: 14,
    scope: 15,
    roles: 16,

    button_Text: L('Button'),
    datePicker_Text: L('Date Picker'),
    entityGrid_Text: L('Item Grid'),
    entityPicker_Text: L('Entity Picker'),
    fileUpload_Text: L('File Upload'),
    image_Text: L('Image'),
    multiSelect_Text: L('Multi Select'),
    numberPicker_Text: L('Number Picker'),
    profileImage_Text: L('Profile Image'),
    searchSelect_Text: L('Search Select'),
    singleSelect_Text: L('Single Select'),
    switch_Text: L('Yes/No Switch'),
    textBox_Text: L('Textbox'),
    paragraph_Text: L('Paragrah Text'),
    approval_Text: L('Approval'),
    scope_Text: L('Scope'),
    roles_Text: L('Roles'),
};
