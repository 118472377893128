import { L } from "../../lib/abpUtility";

export const StateTypes = {
    start: 0,
    middle: 1,
    last: 2,
    terminal: 3,  

    start_text: L('Beginning'),
    middle_text: L('Workflow'),
    last_text: L('End'),
    terminal_text: L('Terminal'),
}
