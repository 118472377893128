import http from '../httpService';
import { DashbordAskDto } from './dto/dashboardAskDto';
import { DashboardColumnDto, DashboardLayoutDto, DashboardRowDto } from './dto/dashboardLayoutDto';
import { AreaChart_Configuration, AreaChart_DataModel, AxisModel } from '../../components/DataModels/areaChart_Configuration'
import { BarChart_Configuration, BarChart_DataModel } from '../../components/DataModels/barChart_Configuration';
import { Bullet_Configuration, Bullet_DataModel } from '../../components/DataModels/bullet_Configuration';
import { CirclePacking_Configuration, CirclePacking_DataModel, CirclePacking_DataModelChildren } from '../../components/DataModels/circlePacking_Configuration';
import { ColumnGraph_Configuration, ColumnGraph_DataModel } from '../../components/DataModels/columnGraph_Configuration';
import { DonutChart_Configuration, DonutChart_DataModel } from '../../components/DataModels/donutChart_Configuration';
import { FunnelChart_Configuration, FunnelChart_DataModel } from '../../components/DataModels/funnelChart_Configuration';
import { Gauge_Configuration } from '../../components/DataModels/gauge_Configuration';
import { HeatMap_Configuration, HeatMap_DataModel } from '../../components/DataModels/heatMap_Configuration';
import { Histogram_Configuration, Histogram_DataModel } from '../../components/DataModels/histogram_Configuration';
import { LineChart_Configuration, LineChart_DataModel } from '../../components/DataModels/lineChart_Configuration';
import { Liquid_Configuration } from '../../components/DataModels/liquid_Configuration';
import { MultiLineChart_Configuration, MultiLineChart_DataModel } from '../../components/DataModels/multiLineChart_Configuration';
import { PieChart_Configuration, PieChart_DataModel } from '../../components/DataModels/pieChart_Configuration';
import { Progress_Configuration } from '../../components/DataModels/progress_Configuration';
import { RadarChart_Configuration } from '../../components/DataModels/radarChart_Configuration';
import { RadialBarChart_Configuration, RadialBarChart_DataModel } from '../../components/DataModels/radialBarChart_Configuration';
import { Ring_Configuration } from '../../components/DataModels/ring_Configuration';
import { RoseChart_Configuration, RoseChart_DataModel } from '../../components/DataModels/roseChart_Configuration';
import { SankeyPlot_Configuration, SankeyPlot_DataModel } from '../../components/DataModels/sankeyPlot_Configuration';
import { ScatterPlot_Configuration, ScatterPlot_DataModel } from '../../components/DataModels/scatterPlot_Configuration';
import { StockPlot_Configuration, StockPlot_DataModel } from '../../components/DataModels/stockPlot_Configuration';
import { SunburstPlot_Configuration, SunburstPlot_DataModel } from '../../components/DataModels/sunburstPlot_Configuration';
import { VennPlot_Configuration, VennPlot_DataModel } from '../../components/DataModels/vennPlot_Configuration';
import { ViolinPlot_Configuration, ViolinPlot_DataModel } from '../../components/DataModels/violinPlot_Configuration';
import { WaterfallChart_Configuration, WaterfallChart_DataModel } from '../../components/DataModels/waterfallChart_Configuration';
import { BoxPlot_Configuration, BoxPlot_DataModel, BoxStyleModel } from '../../components/DataModels/boxPlot_Configuration';
 

class DashboardService {

    public async getUserDashboard(): Promise<DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>> {
        let getResult = await http.get('api/services/app/Apps/GetUserDashboard', {});
        return getResult.data.result;
    }

    public async getPublicDashboard(): Promise<DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>> {
        let getResult = await http.get('api/services/app/Apps/GetPublicDashboard', {});
        return getResult.data.result;
    }

    //-------------------------------------------------------------------------------------

    public async getWidget_AreaChart(askDto: DashbordAskDto): Promise<AreaChart_Configuration<AreaChart_DataModel, AxisModel>> {
        let getResult = await http.get('api/services/app/Widgets/AreaChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_WaterfallChart(askDto: DashbordAskDto): Promise<WaterfallChart_Configuration<WaterfallChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/WaterfallChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_ViolinPlot(askDto: DashbordAskDto): Promise<ViolinPlot_Configuration<ViolinPlot_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/ViolinPlot', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_VennPlot(askDto: DashbordAskDto): Promise<VennPlot_Configuration<VennPlot_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/VennPlot', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_SunburstPlot(askDto: DashbordAskDto): Promise<SunburstPlot_Configuration<SunburstPlot_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/SunburstPlot', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_StockPlot(askDto: DashbordAskDto): Promise<StockPlot_Configuration<StockPlot_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/StockPlot', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_ScatterPlot(askDto: DashbordAskDto): Promise<ScatterPlot_Configuration<ScatterPlot_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/ScatterPlot', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_SankeyPlot(askDto: DashbordAskDto): Promise<SankeyPlot_Configuration<SankeyPlot_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/SankeyPlot', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_RoseChart(askDto: DashbordAskDto): Promise<RoseChart_Configuration<RoseChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/RoseChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_Ring(askDto: DashbordAskDto): Promise<Ring_Configuration> {
        let getResult = await http.get('api/services/app/Widgets/Ring', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_RadialBarChart(askDto: DashbordAskDto): Promise<RadialBarChart_Configuration<RadialBarChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/RadialBarChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_RadarChart(askDto: DashbordAskDto): Promise<RadarChart_Configuration> {
        let getResult = await http.get('api/services/app/Widgets/RadarChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_Progress(askDto: DashbordAskDto): Promise<Progress_Configuration> {
        let getResult = await http.get('api/services/app/Widgets/Progress', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_PieChart(askDto: DashbordAskDto): Promise<PieChart_Configuration<PieChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/PieChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_MultiLineChart(askDto: DashbordAskDto): Promise<MultiLineChart_Configuration<MultiLineChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/MultiLineChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_Liquid(askDto: DashbordAskDto): Promise<Liquid_Configuration> {
        let getResult = await http.get('api/services/app/Widgets/Liquid', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_LineChart(askDto: DashbordAskDto): Promise<LineChart_Configuration<LineChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/LineChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_Histogram(askDto: DashbordAskDto): Promise<Histogram_Configuration<Histogram_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/Histogram', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_HeatMap(askDto: DashbordAskDto): Promise<HeatMap_Configuration<HeatMap_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/HeatMap', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_Gauge(askDto: DashbordAskDto): Promise<Gauge_Configuration> {
        let getResult = await http.get('api/services/app/Widgets/Gauge', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_FunnelChart(askDto: DashbordAskDto): Promise<FunnelChart_Configuration<FunnelChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/FunnelChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_DonutChart(askDto: DashbordAskDto): Promise<DonutChart_Configuration<DonutChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/DonutChart', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_ColumnGraph(askDto: DashbordAskDto): Promise<ColumnGraph_Configuration<ColumnGraph_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/ColumnGraph', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_CirclePacking(askDto: DashbordAskDto): Promise<CirclePacking_Configuration<CirclePacking_DataModel<CirclePacking_DataModelChildren>>> {
        let getResult = await http.get('api/services/app/Widgets/CirclePacking', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_Bullet(askDto: DashbordAskDto): Promise<Bullet_Configuration<Bullet_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/Bullet', { params: askDto });
        return getResult.data.result;
    }

    public async getWidget_BoxPlot(askDto: DashbordAskDto): Promise<BoxPlot_Configuration<BoxPlot_DataModel, BoxStyleModel>> {
        let getResult = await http.get('api/services/app/Widgets/BoxPlot', { params: askDto });
        return getResult.data.result;
    }
  
    public async getWidget_BarChart(askDto: DashbordAskDto): Promise<BarChart_Configuration<BarChart_DataModel>> {
        let getResult = await http.get('api/services/app/Widgets/BarChart', { params: askDto });
        return getResult.data.result;
    }

}
export default new DashboardService();
