import './App.css';

import * as React from 'react';
import Router from './components/Router';
import SessionStore from './stores/sessionStore';
import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper';
import Stores from './stores/storeIdentifier';
import { inject } from 'mobx-react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


export interface IAppProps {
  sessionStore?: SessionStore;

}

export interface IAppState {
  sessionComplete: boolean;
  loadTimer: number;
}


@inject(Stores.SessionStore)
class App extends React.Component<IAppProps, IAppState> {

  state = {
    sessionComplete: false,
    loadTimer: 0

  }


  async componentDidMount() {

    try {
      await this.props.sessionStore!.getCurrentLoginInformations();

      //if (!!this.props.sessionStore!.currentLogin.user && this.props.sessionStore!.currentLogin.application.features['SignalR']) {
        if (!!this.props.sessionStore!.currentLogin.user) {
        if (this.props.sessionStore!.currentLogin.application.features['SignalR.AspNetCore']) {
          SignalRAspNetCoreHelper.initSignalR();
        }

      }
      this.setState({ sessionComplete: true })
    }
    catch (error) { 

      this.setState({ sessionComplete: true })
    }


  }

  public render() {
    return <div style={{ background: 'transparent'}}>


      {this.state.sessionComplete === false ?
        <div style={{ height: '150px', margin: 'auto', marginTop: '40px', width: '95%', textAlign: 'center', background: 'transparent' }}>

          <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>


            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#758082' }} spin />} />


          </div>
        </div> : <Router  />
      }






    </div>;
  }
}

export default App;
