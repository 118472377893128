import { L } from "../../lib/abpUtility";

export const ApprovalTypes = {
    user: 0,
    persona: 1,
    team: 2, 
    all: 3,
 
    user_text: L('By User'),
    persona_text: L('By Persona'),
    team_text: L('By Team'), 
    all_text: L('All'), 
}
