
import { ReportSummaryReturnDto } from './dto/summaries/reportSummaryReturnDto'
import { ReportReturnDto } from './dto/reportReturnDto';
import { ReportRecordReturnDto, ReportRecordReturnListDto } from './dto/reportRecordReturnListDto';
import { ReportRecordAskDto } from './dto/reportRecordAskDto';
import { ReportCreateDto } from './dto/reportCreateDto';
import { ReportAskDto } from './dto/reportAskDto';
import http from '../httpService';
import { ReportEntityDto } from './dto/entities/reportEntityDto';
import { ReportItemDto } from './dto/entities/reportItemDto';
import { ReportOptionDto } from './dto/entities/reportOptionDto';
import { ReportRecordDto } from './dto/entities/reportRecordDto';
import { ReportStateDto } from './dto/entities/reportStateDto';
import { ReportTypeDto } from './dto/entities/reportTypeDto';
import { ReportTemplateRecord } from './dto/reportTemplateRecord';
import { PagedResultDto } from '../dto/pagedResultDto';
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { ReportTemplateDto } from './dto/entities/reportTemplateDto';
import { ReportEntitySummaryDto } from './dto/summaries/reportEntitySummaryDto';
import { ReportItemSummaryDto } from './dto/summaries/reportItemSummaryDto';
import { ReportOptionSummaryDto } from './dto/summaries/reportOptionSummaryDto';
import { ReportStateSummaryDto } from './dto/summaries/reportStateSummaryDto';
import { ReportTemplateSummaryDto } from './dto/summaries/reportTemplateSummaryDto';
import { ReportTypeSummaryDto } from './dto/summaries/reportTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto'; 
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
import { InfoHubArticleSummaryDto } from "../infoHub/dto/infoHubArticleSummaryDto";
import { CommunicationListSummaryDto } from '../dto/summaries/communicationListSummaryDto';
import { InventoryListSummaryDto } from '../dto/summaries/inventoryListSummaryDto';
import { MenuListSummaryDto } from '../dto/summaries/menuListSummaryDto';
import { PaymentsListSummaryDto } from '../dto/summaries/paymentsListSummaryDto';
import { ReportsListSummaryDto } from '../dto/summaries/reportsListSummaryDto';
import { SchedulingListSummaryDto } from '../dto/summaries/schedulingListSummaryDto';
import { WorkOrderListSummaryDto } from '../dto/summaries/workOrderListSummary';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { IntegrationListSummaryDto } from '../dto/summaries/integrationListSummaryDto';
 

class ReportService { 
 
    public async get(askDto: ReportAskDto): Promise<ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Reports/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Reports/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Reports/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Reports/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: ReportAskDto): Promise<ReportSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, ReportEntitySummaryDto, ReportTypeSummaryDto, ReportTemplateSummaryDto, ReportStateSummaryDto, ReportOptionSummaryDto, ReportItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Reports/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
 
    public async getRelatedUsers(askDto: ReportRecordAskDto): Promise<ReportRecordReturnListDto<ReportRecordReturnDto>> {
        let getRelatedUsersResult = await http.get('api/services/app/Reports/GetRelatedUsers', { params: askDto });
        return getRelatedUsersResult.data.result;
    }

    public async getTemplates(askDto: ReportAskDto): Promise<PagedResultDto<ReportTemplateRecord>> {
        let getTemplatesResult = await http.get('api/services/app/TemplateRecord/Get', { params: askDto });
        return getTemplatesResult.data.result;
    }

    public async getRecords(askDto: ReportAskDto): Promise<PagedResultDto<ReportTemplateRecord>> {
        let getRecordsResult = await http.get('api/services/app/TemplateRecord/Get', { params: askDto });
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------
 
    public async updateRecord(askDto: ReportRecordDto): Promise<PagedResultDto<ReportRecordDto>> {
        let getRecordsResult = await http.put('api/services/app/ReportRecord/Update', askDto);
        return getRecordsResult.data.result;
    }

    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Reports/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Reports/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Reports/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Reports/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: ReportAskDto): Promise<ReportSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, ReportEntitySummaryDto, ReportTypeSummaryDto, ReportTemplateSummaryDto, ReportStateSummaryDto, ReportOptionSummaryDto, ReportItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getRecordsResult = await http.get('api/services/app/Reports/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Reports/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Reports/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }
 
}

export default new ReportService();
