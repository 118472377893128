class TenantModel {
  id!: number;
  tenancyName!: string;
  name!: string;
  adminEmailAddress!: string;
  connectionString!: string;
  isActive!: boolean;
  title?: string;
  currentState!: number;
  
  relatedImage?: string;
  subscriberFirstName?: string;
  subscriberLastName?: string;
  subscriberEmail?: string;
  subscriberPhone?: string;

  ftpurl?: string;
  ftpUser?: string;
  ftpPassword?: string;
  profileImageID?: number;

  subscriptionStart?: any;
  subscriptionExpire?: any;
  subscriptionType?: any;
  subscriptionRate?: any;

}

export default TenantModel;
