import { action, observable } from 'mobx';
import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import sessionService from '../services/session/sessionService';
 


class SessionStore {
  @observable currentLogin: GetCurrentLoginInformations = new GetCurrentLoginInformations();
  


  @action
  async getCurrentLoginInformations() {


    //console.warn('Getting Login Info: ' + dayjs(new Date()).toString())
    let result = await sessionService.getCurrentLoginInformations();
    //console.warn('Got Login Info: ' + dayjs(new Date()).toString())
 
    //console.warn('=----------------')
    //console.warn(JSON.stringify(result))

    this.currentLogin = result;
 
    //this.currentLogin.tenant.tenancyName = 'Company Wireless';
    //this.currentLogin.tenant.profileImageURL = 'https://files.swilab.com/devLogo.png';
    //this.currentLogin.user.userName = 'Benjamin Allen';
    //this.currentLogin.user.profileImageURL = 'https://files.swilab.com/defaultProfileImg.png';

    return result;

  }



}

export default SessionStore;
