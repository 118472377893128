 
import TenantLoginInfoDto from './tenantLoginInfoDto';
import UserLoginInfoDto from './userLoginInfoDto';

export class GetCurrentLoginInformations {
  application!: any;
  user!: UserLoginInfoDto;
  tenant!: TenantLoginInfoDto;
  roles!: string[];
  personas?: number[];
  teams?: number[];
}
